import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SearchableDropdownModel,
  ZuiFiltersBarComponent
} from '@zonar-ui/filter';
import { FormGroup } from '@angular/forms';
import {
  PASSHOLDER_TYPES,
  ZpxApiFilterNames
} from '@src/app/models/zpx-api.model';
import { TablePassholdersFilterBarService } from './table-passholders-filter-bar.service';
import { AppService } from '@src/app/app.service';
import { combineLatest, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  switchMapTo,
  takeUntil
} from 'rxjs/operators';
import { TablePassholdersFilterGroupValue } from './models/table-passholders-filter-bar.model';
import _ from 'lodash';

@Component({
  selector: 'app-table-passholders-filter-bar',
  standalone: true,

  imports: [CommonModule, ZuiFiltersBarComponent],
  templateUrl: './table-passholders-filter-bar.component.html',
  styleUrls: ['./table-passholders-filter-bar.component.scss']
})
export class TablePassholdersFilterBarComponent
  implements OnInit, OnDestroy, OnChanges
{
  constructor(
    public filterBarService: TablePassholdersFilterBarService,
    private appService: AppService
  ) {}

  @Input() isMobile = false;
  @Input() toggleDisplay: boolean;
  @Input()
  set passholderType(passholderType: PASSHOLDER_TYPES) {
    this._passholderType = passholderType;
  }
  get passholderType(): PASSHOLDER_TYPES {
    return this._passholderType;
  }
  @Output() mobileDisplayed = new EventEmitter();

  private _passholderType: PASSHOLDER_TYPES;
  private unsubscribe$ = new Subject<void>();
  shouldSendDivisionId: boolean = null;
  changedEvent: any = null;
  filterList: SearchableDropdownModel[];
  filterFormGroup = new FormGroup({});
  selectedDivisionId: string = null;

  mobileViewClasses = {
    'mobile-view': this.isMobile ? true : false,
    hidden: false
  };

  removeDivisionFilter() {
    const first: SearchableDropdownModel = this.filterList[0];
    if (first.options.fgControlName === ZpxApiFilterNames.DIVISION_ID) {
      this.filterList.shift();
    }
  }

  setDivisionDefaultSelection(divisionId: string) {
    this.filterList[0] = this.filterBarService.getDivisonModel(divisionId);
  }

  removeCustomColumnFilters() {
    this.filterList = this.filterList.filter((f) => !(f as any).customColumnId);
  }
  addCustomColumnFilters(customColumnFilters) {
    this.filterList = this.filterList.concat(customColumnFilters);
  }

  ngOnInit() {
    this.filterList = this.filterBarService.getCommonColumnsFilters();
    this.appService
      .shouldLoadData$()
      .pipe(
        filter(Boolean),
        switchMapTo(
          combineLatest([
            this.appService.selectedDivisionId$.pipe(filter(Boolean)),
            this.appService.isSingleDivisionUser$().pipe(distinctUntilChanged())
          ]).pipe(
            map(([divId, singleDivUser]) => {
              this.selectedDivisionId = divId as string;
              singleDivUser
                ? this.removeDivisionFilter()
                : this.setDivisionDefaultSelection(this.selectedDivisionId);
            })
          )
        ),
        switchMapTo(
          this.filterBarService.setCommonColumnFiltersOptions(
            this.passholderType
          )
        ),
        switchMapTo(
          this.filterBarService.getCustomColumnFilters$(this.passholderType)
        ),
        map((customColumnFilters) => {
          this.removeCustomColumnFilters(); // remove previously applied custom column filters
          this.addCustomColumnFilters(customColumnFilters);
          return customColumnFilters;
        }),
        switchMap((customColumnFilters) =>
          this.filterBarService.getCustomColumnsFiltersOptions(
            customColumnFilters
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isMobile'] !== undefined) {
      // ensure that filter bar is hidden the first time mobile view is entered (either on init, or when dynamically resizing the page)
      this.mobileViewClasses.hidden = changes['isMobile'].currentValue === true;
      this.mobileViewClasses['mobile-view'] = changes['isMobile'].currentValue;
      return;
    }
    if (changes['toggleDisplay'] !== undefined) {
      this.mobileViewClasses.hidden = !this.mobileViewClasses.hidden;

      this.mobileDisplayed.emit(!this.mobileViewClasses.hidden);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // Ensure passholders table is displayed initially when switching between passholder types
    if (this.isMobile) {
      this.mobileDisplayed.emit(false);
    }
  }

  filterChanged(e) {
    if (this.changedEvent === null) this.changedEvent = e;
    if (!_.isEqual(this.changedEvent, e)) {
      this.changedEvent = e;
      this.shouldSendDivisionId =
        Boolean(e?.division_id?.selected) && Object.keys(e).length === 1;

      if (this.shouldSendDivisionId) {
        const incomingDivisionId = e.division_id.selected;

        // saving of selectedDivisionId prevents emissions of the same division id and prevents redundant api calls
        if (this.selectedDivisionId !== incomingDivisionId) {
          this.selectedDivisionId = incomingDivisionId;
          this.appService.selectedDivisionId$.next(this.selectedDivisionId);
        }
      } else {
        // We're not switching division IDs, so the filter values need to be acted upon.
        // We need to do this little bit because divisionId is a string, and we also only
        // care about the state of the filters that have been selected
        const filterFormState: TablePassholdersFilterGroupValue =
          this.filterFormGroup.value;
        const selectedFilterObj = {};
        Object.keys(filterFormState)
          .filter(
            (ff) =>
              Array.isArray(filterFormState[ff]) &&
              filterFormState[ff].length > 0
          )
          .forEach(
            (selectedFilter) =>
              (selectedFilterObj[selectedFilter] =
                filterFormState[selectedFilter])
          );
        this.filterBarService.passholdersFilterChanged$.next(true);

        this.filterBarService.parseUserSelectedFilters(selectedFilterObj);
      }
    }
  }

  hideSelf() {
    this.mobileViewClasses.hidden = true;
    this.mobileDisplayed.emit(false);
  }
}
