import { STATUSES } from './zpx-api.model';

export enum LEGACY_TYPES {
  LEGACY_LOCATION = 'LEGACY_LOCATION',
  LEGACY = 'LEGACY'
}

export interface LegacyLocation {
  id: string;
  name: string;
  parentId?: string;
  status?: STATUSES;
  created?: string;
  modified?: string;
  userId?: string;
  children?: string[];
  companyId?: string;
  legacyAccountCode?: string;
  locationId?: string;
  type?: LEGACY_TYPES.LEGACY_LOCATION;
  auditInfo?: any;
  managedEntity?: any;
  isChecked?: boolean;
}

export interface EntityApiParams {
  companyId?: string;
  divisionId?: string;
  allChildren?: boolean;
  sort?: string;
  page?: number;
  per_page?: number;
}
