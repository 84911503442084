<h1>Z Pass Management</h1>
<mat-tab-group
  [selectedIndex]="0"
  (selectedTabChange)="changePassholder($event)">
  <mat-tab label="Riders">
    <ng-template matTabContent>
      <app-table-passholders-filter-bar
        passholderType="student"
        [isMobile]="device.isMobile"
        [toggleDisplay]="toggleFilterBarDisplay"
        (mobileDisplayed)="mobileDisplayHandler($event)">
      </app-table-passholders-filter-bar>
      <app-filter-toggle
        *ngIf="device.isMobile"
        (filterToggled)="onPassholdersMobileFilterToggled($event)"
        [style.display]="mobileViewClasses.hidden? 'none': null">
      </app-filter-toggle>
      <app-passholder-table
        passholderType="student"
        [ngClass]="mobileViewClasses">
      </app-passholder-table>
    </ng-template>
  </mat-tab>

  <mat-tab label="Aides">
    <ng-template matTabContent>
      <app-table-passholders-filter-bar
        passholderType="aide"
        [isMobile]="device.isMobile"
        [toggleDisplay]="toggleFilterBarDisplay"
        (mobileDisplayed)="mobileDisplayHandler($event)">
      </app-table-passholders-filter-bar>
      <app-filter-toggle
        *ngIf="device.isMobile"
        (filterToggled)="onPassholdersMobileFilterToggled($event)"
        [style.display]="mobileViewClasses.hidden? 'none': null">
      </app-filter-toggle>
      <app-passholder-table passholderType="aide" [ngClass]="mobileViewClasses">
      </app-passholder-table>
    </ng-template>
  </mat-tab>

  <mat-tab label="Drivers"
    ><ng-template matTabContent>
      <app-table-passholders-filter-bar
        passholderType="driver"
        [isMobile]="device.isMobile"
        [toggleDisplay]="toggleFilterBarDisplay"
        (mobileDisplayed)="mobileDisplayHandler($event)">
      </app-table-passholders-filter-bar>
      <app-filter-toggle
        *ngIf="device.isMobile"
        (filterToggled)="onPassholdersMobileFilterToggled($event)"
        [style.display]="mobileViewClasses.hidden? 'none': null">
      </app-filter-toggle>
      <app-passholder-table
        passholderType="driver"
        [ngClass]="mobileViewClasses">
      </app-passholder-table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
