import { Injectable } from '@angular/core';
import { AuthHeadersService } from '../auth-headers-service/auth-headers.service';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { AppService } from '@src/app/app.service';
import { SelectedCompanyService } from '../selected-company/selected-company.service';

import { EntityApiClientService } from '../entity-api-client/entity-api-client.service';
import { EntityApiParams } from '@src/app/models/entity-api.model';
@Injectable({
  providedIn: 'root'
})
export class GetAssetsService {
  constructor(
    private authService: AuthHeadersService,
    private appService: AppService,
    private companyService: SelectedCompanyService,
    // was trying to do some clever class inheritance with EntityApiClientService, but Angular's dependency injection makes this hard to do cleanly, so we are just injecting the service again to get commonly used methods
    private entityApiService: EntityApiClientService
  ) {}

  ASSETS = 'assets';

  defaultParams: EntityApiParams = {
    page: 1,
    sort: 'name',
    allChildren: true
  };

  getAssets(): Observable<
    {
      name: string;
      id: string;
      [name: string]: any;
    }[]
  > {
    const headersAndEntityIds$ = combineLatest([
      this.authService.getAuthHeaders().pipe(filter((h) => h !== null)),
      this.companyService.getCompanyId().pipe(filter((c) => c !== null)),
      this.appService.selectedDivisionId$.pipe(filter((d) => d !== null))
    ]);

    return headersAndEntityIds$.pipe(
      switchMap(([headers, companyId, divisionId]) => {
        // Entity API needs divisionId AND companyId together if we need to filter down to only assets for the user's division
        this.defaultParams.divisionId = divisionId;
        this.defaultParams.companyId = companyId;

        let assets = [];

        return this.entityApiService
          .get(this.ASSETS, this.defaultParams, headers)
          .pipe(
            mergeMap((firstPageAssetsResponse) => {
              assets = firstPageAssetsResponse.body;
              const totalPages =
                this.entityApiService.getResponseHeaderAsNumber(
                  firstPageAssetsResponse,
                  this.entityApiService.PAGE_COUNT
                );

              // since ACE team paginates their data, we have to determine if we need to fetch more pages to get a full list of assets
              if (totalPages > 1) {
                // call subsequent pages
                const pagecalls = [];
                for (let i = 2; i <= totalPages; i++) {
                  pagecalls.push(
                    this.entityApiService.get(
                      this.ASSETS,
                      {
                        ...this.defaultParams,
                        page: i
                      },
                      headers
                    )
                  );
                }

                return merge(...pagecalls).pipe(
                  map((response) => {
                    assets = [...assets, ...(response as any).body];
                    return assets;
                  })
                );
              }

              return of(assets);
            })
          );
      })
    );
  }
}
