import { Injectable } from '@angular/core';
import {
  CustomTypeColumn,
  PASSHOLDER_COMMON_COLUMNS,
  PASSHOLDER_TYPES,
  ZpxApiGetCustomTypeColumnValuesResponse,
  ZpxApiGetPassholderCommonColumnsResponse
} from '@src/app/models/zpx-api.model';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  take
} from 'rxjs/operators';
import { ZpxApiService } from '../zpx-api-service/zpx-api.service';
import { AppService } from '@src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ManagePassholdersService {
  /* 
  
  This is the highest level service used to manage state and share information via Behavior Subjects across components specifically related to the ZPass Managemnent view.

  Its function is very similar to AppService, but can be treated as "one level lower". However, because this service is provided in root, its state is
  accessible throughout the entire application, and is indeed useful for certain ZPass Event-related components as well (such as populating the option values for certain filters).

  This service also functions as a control layer between the ZpxApiService network layer and Passholder Management-related components that require the results of those requests.
  
  Part of the utility of this service is the caching system that's been set-up for passholder common values (eg first names, last names, card numbers), as well as custom columns, and custom column values.

  Methods have been created to support refreshing cached data, and are used throughout the app whenever a user performs an action that should result
  in the displayed data being updated to reflect those changes (eg adding/editing a passholder, modifying custom columns, importing passholders, etc).

  */

  constructor(
    private zpxApiService: ZpxApiService,
    private appService: AppService
  ) {
    this.appService.selectedDivisionId$
      .pipe(
        filter(Boolean),
        map((divisionId: string) => (this._selectedDivisionId = divisionId))
      )
      .subscribe();
  }
  private _selectedDivisionId: string;

  /**
   * PASSHOLDER TYPE & PASSHOLDER TYPE ID
   */
  readonly selectedPassholderType$: BehaviorSubject<PASSHOLDER_TYPES> =
    new BehaviorSubject(null);

  public setSelectedPassholderType(passholderType: PASSHOLDER_TYPES) {
    this.selectedPassholderType$.next(passholderType);
  }

  readonly selectedPassholderTypeId$: Observable<any> =
    this.selectedPassholderType$.pipe(
      filter(Boolean),
      switchMap((passholderType) => {
        return this.appService.passholderTypes$.pipe(
          filter((types) => Boolean(types)),
          map(
            (types) =>
              types.filter((type) => type.name === passholderType)[0].id
          )
        );
      })
    );

  readonly refreshPassholdersSubject = new Subject<PASSHOLDER_TYPES>();
  refreshPassholders(passholderType: PASSHOLDER_TYPES) {
    this.refreshPassholdersSubject.next(passholderType);
  }

  /**
   * CUSTOM COLUMNS
   */
  private _customColumnsCache = new Map<
    string,
    Map<PASSHOLDER_TYPES, Observable<CustomTypeColumn[]>>
  >();
  private _refreshCustomColumnsSubject = new Subject<{
    divisionId: string;
    passholderType: PASSHOLDER_TYPES;
  }>();

  public getCustomColumns(
    divisionId: string,
    passholderType: PASSHOLDER_TYPES
  ): Observable<CustomTypeColumn[]> {
    if (!this._customColumnsCache.has(divisionId)) {
      this._customColumnsCache.set(
        divisionId,
        new Map<PASSHOLDER_TYPES, Observable<CustomTypeColumn[]>>()
      );
    }

    const subCache = this._customColumnsCache.get(divisionId);

    if (!subCache.has(passholderType)) {
      const initialRequest$ = this.zpxApiService
        .getCustomColumnsByPassholderType(passholderType, divisionId)
        .pipe(
          first(),
          catchError((error) => {
            subCache.delete(passholderType);
            return of(null);
          })
        );
      const refreshRequest$ = this._refreshCustomColumnsSubject.pipe(
        filter(
          (refreshKeys) =>
            refreshKeys.divisionId === divisionId &&
            refreshKeys.passholderType === passholderType
        ),
        switchMap(() =>
          this.zpxApiService
            .getCustomColumnsByPassholderType(passholderType, divisionId)
            .pipe(
              first(),
              catchError((error) => {
                subCache.delete(passholderType);
                return of(null);
              })
            )
        )
      );
      const data$ = merge(initialRequest$, refreshRequest$).pipe(
        shareReplay(1)
      );

      subCache.set(passholderType, data$);
    }

    return subCache.get(passholderType);
  }

  public refreshCustomColumns(
    divisionId: string,
    passholderType: PASSHOLDER_TYPES
  ): void {
    this._refreshCustomColumnsSubject.next({ divisionId, passholderType });
  }

  /**
   * COMMON COLUMN VALUES
   */
  private _commonColumnValuesCache = new Map<
    string,
    Map<
      PASSHOLDER_TYPES,
      Map<
        PASSHOLDER_COMMON_COLUMNS,
        Observable<ZpxApiGetPassholderCommonColumnsResponse>
      >
    >
  >();
  private _refreshCommonColumnValuesSubject = new Subject<{
    divisionId: string;
    passholderType: PASSHOLDER_TYPES;
    commonColumn: PASSHOLDER_COMMON_COLUMNS;
  }>();

  getPassholderCommonColumnValues(
    divisionId: string,
    passholderType: PASSHOLDER_TYPES,
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ): Observable<ZpxApiGetPassholderCommonColumnsResponse> {
    if (!this._commonColumnValuesCache.has(divisionId)) {
      this._commonColumnValuesCache.set(
        divisionId,
        new Map<
          PASSHOLDER_TYPES,
          Map<
            PASSHOLDER_COMMON_COLUMNS,
            Observable<ZpxApiGetPassholderCommonColumnsResponse>
          >
        >()
      );
    }

    const topCache = this._commonColumnValuesCache.get(divisionId);

    if (!topCache.has(passholderType)) {
      topCache.set(
        passholderType,
        new Map<
          PASSHOLDER_COMMON_COLUMNS,
          Observable<ZpxApiGetPassholderCommonColumnsResponse>
        >()
      );
    }

    const subCache = topCache.get(passholderType);

    if (!subCache.has(commonColumn)) {
      const initialRequest$ = this.zpxApiService
        .getPassholderCommonColumnValues(passholderType, commonColumn)
        .pipe(
          first(),
          catchError((error) => {
            subCache.delete(commonColumn);
            return of(null);
          })
        );
      const refreshRequest$ = this._refreshCommonColumnValuesSubject.pipe(
        filter(
          (refreshKeys) =>
            refreshKeys.divisionId === divisionId &&
            refreshKeys.commonColumn === commonColumn &&
            refreshKeys.passholderType === passholderType
        ),
        switchMap(() =>
          this.zpxApiService
            .getPassholderCommonColumnValues(passholderType, commonColumn)
            .pipe(
              first(),
              catchError((error) => {
                subCache.delete(commonColumn);
                return of(null);
              })
            )
        )
      );
      const data$ = merge(initialRequest$, refreshRequest$).pipe(
        shareReplay(1)
      );

      subCache.set(commonColumn, data$);
    }

    return subCache.get(commonColumn);
  }

  refreshCommonColumnValues(
    divisionId: string,
    passholderType: PASSHOLDER_TYPES,
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ): void {
    this._refreshCommonColumnValuesSubject.next({
      divisionId,
      passholderType: passholderType,
      commonColumn: commonColumn
    });
  }

  refreshAllCommonColumnValues() {
    this._commonColumnValuesCache
      .get(this._selectedDivisionId)
      .forEach((commonColValMap, passholderType) =>
        commonColValMap.forEach((_, commonCol) =>
          this.refreshCommonColumnValues(
            this._selectedDivisionId,
            passholderType,
            commonCol
          )
        )
      );
  }

  /**
   * CUSTOM COLUMN VALUES
   * ...These don't require a top-level divisionId key, since the key used is a uuid,
   * which will be unique across all divisions
   */

  private _customColumnsValuesCache = new Map<
    string,
    Observable<ZpxApiGetCustomTypeColumnValuesResponse>
  >();

  private _refreshCustomColumnValuesSubject = new Subject<string>();

  public getCustomColumnValues(
    customColumnId: string
  ): Observable<ZpxApiGetCustomTypeColumnValuesResponse> {
    if (!this._customColumnsValuesCache.has(customColumnId)) {
      const initialRequest$ = this.zpxApiService
        .getCustomColumnValues(customColumnId)
        .pipe(
          first(),
          catchError((error) => {
            this._customColumnsValuesCache.delete(customColumnId);
            return of(null);
          })
        );

      const refreshRequest$ = this._refreshCustomColumnValuesSubject.pipe(
        filter((refreshKey) => refreshKey === customColumnId),
        switchMap(() =>
          this.zpxApiService.getCustomColumnValues(customColumnId).pipe(
            first(),
            catchError((error) => {
              this._customColumnsValuesCache.delete(customColumnId);
              return of(null);
            })
          )
        )
      );

      const data$ = merge(initialRequest$, refreshRequest$).pipe(
        shareReplay(1)
      );

      this._customColumnsValuesCache.set(customColumnId, data$);
    }

    return this._customColumnsValuesCache.get(customColumnId);
  }

  public refreshCustomColumnValuesById(customColumnId: string): void {
    this._refreshCustomColumnValuesSubject.next(customColumnId);
  }

  public refreshAllCustomColumnValues() {
    this._customColumnsValuesCache.forEach((_, customColumnId) =>
      this.refreshCustomColumnValuesById(customColumnId)
    );
  }
}
