import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import {
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
  first
} from 'rxjs/operators';
import { ZpxApiService } from '@services/zpx-api-service/zpx-api.service';

import {
  PassholdersReportHttpResponseBody,
  ZpxApiPassholderParams
} from '@src/app/models/zpx-api.model';

export function uniqueIdValidator(
  zpxApiService: ZpxApiService
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return control.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((uniqueIdValue) => {
        if (uniqueIdValue === '') {
          return of(null);
        }
        const params: ZpxApiPassholderParams = {
          exsid: [control.value],
          limit: 1
        };
        return zpxApiService.getPassholders(params).pipe(
          map((resp: PassholdersReportHttpResponseBody) => {
            return resp.total_count > 0 ? { uniqueIdTaken: true } : null;
          }),
          catchError(() => of(null))
        );
      }),
      first()
    );
  };
}
