import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  first,
  map,
  take,
  takeUntil
} from 'rxjs/operators';

import { PendoService } from '@zonar-ui/analytics';
import { PermissionsService } from '@zonar-ui/auth';

import { appName } from '@environments/shared';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { DeviceDetectionService } from './services/device-detection/device-detection.service';
import { SidenavHeaderConfig } from '@config/sidenav.config';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { UserCompany } from '@app/models/user.company.model';
import { SelectedCompanyService } from './services/selected-company/selected-company.service';
import { AppService } from './app.service';
import { FaviconsService } from '@zonar-ui/core';
import { ZpxApiService } from './services/zpx-api-service/zpx-api.service';

import { MatDialog } from '@angular/material/dialog';
import { DivisionPickerModalComponent } from '@src/app/components/modals/division-picker-modal/division-picker-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  title = appName;

  currentCompany$ = new BehaviorSubject<UserCompany>({
    title: '',
    value: ''
  });
  sidenavHeader = SidenavHeaderConfig;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    footerOffset: false
  };
  divisionId: string = null;
  toggleFilterBarDisplay = true;

  mobileViewClasses = {
    'mobile-view': this.device.isMobile ? true : false,
    hidden: false
  };

  constructor(
    private auth: AuthService,
    private datadog: DataDogService,
    private pendoService: PendoService,
    private permissionsService: PermissionsService,
    public device: DeviceDetectionService,
    private selectedCompanyService: SelectedCompanyService,
    private _cdRef: ChangeDetectorRef,
    private appService: AppService,
    private faviconService: FaviconsService,
    private zpxApiService: ZpxApiService,
    private dialog: MatDialog
  ) {
    this.auth.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => Boolean(isAuthenticated)),
        first()
      )
      .subscribe(() => {
        this.datadog.startSessionReplayRecording();
      });
  }

  ngOnInit(): void {
    // get app-wide passholder types and store them in state
    this.zpxApiService
      .getPassholderTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((passholderTypes) => {
        this.appService.passholderTypes$.next(passholderTypes);
      });

    // get app-wide groups and store them in state
    this.zpxApiService
      .getGroups()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((groups) => {
        this.appService.groupsByNameAsc$.next(groups);
      });

    // get app-wide legacy locations and store them in state
    this.appService
      .getLegacyLocations$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((legacyLocations) => {
        const alphaLocations = legacyLocations.slice().sort((a, b) => {
          const _a = a?.name?.toLowerCase();
          const _b = b?.name?.toLowerCase();
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        });
        this.appService.legacyLocations$.next(alphaLocations);
      });

    // when we initialize pendo, some of the api calls require a zoner owner id, which can only be attached to the
    // request after the permissions are loaded. therefore, we must wait to initialize pendo until after the
    // permissions are loaded. ultimately, this logic should be handled in the pendo service itself as described here:
    // https://jira.zonarsystems.net/browse/ZTT-1015
    this.permissionsService
      .getPermissions()
      .pipe(debounceTime(250), filter(Boolean), first())
      .subscribe(() => {
        this.pendoService.initialize();
      });

    this.appService
      .getDivisions$()
      .pipe(
        filter((divisions) => Boolean(divisions.length)),
        map((divisions) => {
          this.appService.divisions$.next(divisions);
          if (divisions.length > 1) {
            this.dialog.open(DivisionPickerModalComponent, {
              data: {
                divisions: divisions
              },
              disableClose: true
            });
          } else {
            this.appService.selectedDivisionId$.next(divisions[0].id);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.datadog.stopSessionReplayRecording();
  }

  switchCompany(incomingCompany: UserCompany): void {
    this.selectedCompanyService.setCompanyIdFromSideNav(incomingCompany);
  }

  onSideNavMobileMenuButtonToggled(event): void {
    this.sidenavParams.mobileOpened = event;
    this._cdRef.detectChanges();
  }
}
