<mat-dialog-content>
  <h2 mat-dialog-title>Event</h2>
  <mat-tab-group>
    <mat-tab label="Details">
      <div class="details-tab">
        <div class="top-section">
          <div class="card-group">
            <mat-card
              appearance="outlined"
              class="mat-elevation-z5 card card-top">
              <mat-card-header>
                <p><strong>Rider Information</strong></p>
              </mat-card-header>
              <p>Name: {{ data.event.first_name}} {{data.event.last_name}}</p>
              <p>Unique ID: {{ data.event.unique_id}}</p>
              <p>Card Number: {{ data.event.card_number}}</p>
            </mat-card>

            <mat-card
              appearance="outlined"
              class="mat-elevation-z5 card card-bottom">
              <mat-card-header>
                <p><strong>Asset Number</strong></p>
              </mat-card-header>
              <p>Asset No: {{ data.event.asset_no}}</p>
            </mat-card>
          </div>
        </div>

        <div class="map shadow-border mat-elevation-z5">Map PlaceHolder</div>
      </div>
      <mat-card appearance="outlined" class="mat-elevation-z5 card">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="zone">
            <th mat-header-cell *matHeaderCellDef>Zone</th>
            <td mat-cell *matCellDef="let event">{{event.zone}}</td>
          </ng-container>

          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef>Group</th>
            <td mat-cell *matCellDef="let event">{{event.group}}</td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let event">{{event.address}}</td>
          </ng-container>

          <ng-container matColumnDef="dateTime">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let event">{{event.dateTime}}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card>
    </mat-tab>

    <mat-tab label="History"> </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <div class="exit-button">
    <button
      class="mat-stroked-button mat-button-base accent-button"
      (click)="dialogRef.close()">
      Exit
    </button>
  </div>
</mat-dialog-actions>
