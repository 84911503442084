<div class="content-container" fxFlex>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>Z Pass Events</h1>
    <p id="odometer-disclaimer">
      * Please read the <a>CSV odometer disclaimer.</a>
    </p>
  </div>

  <app-events-filter-bar
    [isMobile]="device.isMobile"
    [toggleDisplay]="toggleFilterBarDisplay"
    (mobileDisplayed)="mobileDisplayHandler($event)"></app-events-filter-bar>
  <app-passholder-events-table [showCheckmarks]="!device.isMobile">
  </app-passholder-events-table>
</div>
