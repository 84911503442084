import { Injectable } from '@angular/core';
import { HttpService } from '@zonar-ui/core';
import { AuthHeadersService } from '../auth-headers-service/auth-headers.service';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  retry
} from 'rxjs/operators';
import { combineLatest, Observable, throwError, forkJoin } from 'rxjs';

import {
  HttpHeaders,
  HttpParams,
  HttpClient,
  HttpResponse
} from '@angular/common/http';
import {
  Group,
  PassholderPatchBody,
  PassholdersReportHttpResponseBody,
  PassholderType,
  ZpxApiCustomColumnParams,
  ZpxApiGetCustomTypeColumnValuesResponse,
  ZpxApiGetPassholderCommonColumnsParams,
  ZpxApiGetPassholderCommonColumnsResponse,
  ZpxApiPassholderHistoryResponse,
  ZpxApiPassholderParams,
  ZpxApiPatchCustomColumnBody,
  ZpxApiPostCustomColumnBody,
  ZpxApiValidatePassholderCSVResponse,
  ZpxGroupDivisionExtended,
  ZpxGroupDivision,
  ZpxGroupDivisionHttpResponseBody,
  PASSHOLDER_TYPES,
  PASSHOLDER_COMMON_COLUMNS,
  CustomTypeColumn,
  GroupBody
} from '../../models/zpx-api.model';
import { AppService } from '@src/app/app.service';
import { DataDogService } from '../data-dog/data-dog.service';
import { SelectedCompanyService } from '../selected-company/selected-company.service';
import {
  ZpxApiEventGetReportBodyParams,
  ZpxApiEventGetReportHttpResponseBody
} from '@src/app/views/events/models/events.model';

@Injectable({
  providedIn: 'root'
})
export class ZpxApiService {
  constructor(
    private httpService: HttpService,
    private authHeadersService: AuthHeadersService,
    private getEnvService: GetEnvironmentService,
    private httpClient: HttpClient,
    private appService: AppService,
    private datadogService: DataDogService,
    private selectedCompanyService: SelectedCompanyService
  ) {}

  url = this.getEnvService.getEnvironmentProperty('apiBase')['url'];
  passholdersReportUrl = `${this.url}/passholders/get-report`;
  passholderTypeUrl = `${this.url}/passholder_types`;
  postPassholderUrl = `${this.url}/passholder/extended`;
  groupsUrl = `${this.url}/zpx-groups`;
  customColumsUrl = `${this.url}/custom-type-column`;
  zpxGroupDivisionUrl = `${this.url}/zpx-group-divisions`;
  eventsReportUrl = `${this.url}/events/get-report`;
  selectedDivisionId = null;
  private _passholderTypes = null;

  _getPassholderTypeCommonColumnValuesUrl(
    passholderType: PASSHOLDER_TYPES,
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ) {
    return `${this.url}/passholders/${passholderType}/${commonColumn}`;
  }

  _getCustomColumnValuesUrl(customColumnId: string) {
    return `${this.url}/custom-type-columns/${customColumnId}/values`;
  }

  getCustomColumnsByPassholderType(
    passholderType: PASSHOLDER_TYPES,
    divisionId: string
  ): Observable<CustomTypeColumn[]> {
    return this.appService.passholderTypes$.pipe(
      filter(Boolean),
      switchMap((types: PassholderType[]) => {
        const passholderTypeId = types.find(
          (t) => t.name === passholderType
        ).id;
        return this.getCustomColumnsByPassholderTypeId(
          passholderTypeId,
          divisionId
        );
      })
    );
  }

  getCustomColumnsByPassholderTypeId(
    passholderTypeId: string,
    divisionId: string
  ): Observable<CustomTypeColumn[]> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      switchMap((headers) => {
        const params = {
          passholder_type_id: passholderTypeId,
          division_id: divisionId
        } as ZpxApiCustomColumnParams;

        return this.httpService.get(
          this.customColumsUrl,
          new HttpParams({ fromObject: { ...params } }),
          new HttpHeaders(headers)
        );
      }),
      map((response) => {
        const customColumns = response.body as CustomTypeColumn[];
        return customColumns.slice().sort((a, b) => a.sequence - b.sequence);
      })
    );
  }

  getPassholders(
    params: ZpxApiPassholderParams,
    body = {}
  ): Observable<PassholdersReportHttpResponseBody> {
    const headersAndDivision = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$.pipe(
        filter((divisionId) => divisionId !== null),
        distinctUntilChanged()
      )
    ]);
    return headersAndDivision.pipe(
      filter(([headers]) => headers !== null),
      switchMap(([headers, divisionId]) => {
        if (divisionId) {
          params.division_id = divisionId;
        }
        body = { ...body, ...params };

        return this.httpService
          .post(
            this.passholdersReportUrl,
            body,
            null, // This is hacky, but we dont need the params to be passed in the request,
            new HttpHeaders(headers)
          )
          .pipe(
            map((response) => {
              return response.body as PassholdersReportHttpResponseBody;
            }),
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error getting passholders for division ${divisionId}: ${err}`
                )
              );
              return throwError('failure to get passholders');
            })
          );
      })
    );
  }
  // This should only be called once, on app initialization. Any other services of methods needing the passholder types should use the appService state behavior subject
  getPassholderTypes(): Observable<PassholderType[]> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      switchMap((headers) => {
        return this.httpService
          .get(this.passholderTypeUrl, null, new HttpHeaders(headers))
          .pipe(
            map((response) => {
              const passholderTypes = response.body as PassholderType[];
              return passholderTypes;
            })
          );
      })
    );
  }
  getEventCommonColumnValues(
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ): Observable<any> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      switchMap(([headers, divisionId]) => {
        const params: ZpxApiGetPassholderCommonColumnsParams = {};
        if (divisionId) {
          params.division_id = divisionId;
        }
        let passholderTypesArray = [
          PASSHOLDER_TYPES.AIDE,
          PASSHOLDER_TYPES.STUDENT,
          PASSHOLDER_TYPES.DRIVER
        ];
        // const getCommonColOptionsObss$ = [];

        const getCommonColOptionsObss$ = passholderTypesArray.map(
          (passType) => {
            const url = this._getPassholderTypeCommonColumnValuesUrl(
              passType,
              commonColumn
            );

            return this.httpService.get(
              url,
              new HttpParams({ fromObject: { ...params } }),
              new HttpHeaders(headers)
            );
          }
        );
        return forkJoin(getCommonColOptionsObss$).pipe(
          map((responses) => {
            // Flatten the array of arrays into a single array
            return responses
              .map(
                (response) =>
                  response.body as ZpxApiGetPassholderCommonColumnsResponse
              )
              .flat();
          })
        );
      })
    );
  }

  getPassholderCommonColumnValues(
    passholderType: PASSHOLDER_TYPES,
    commonColumn: PASSHOLDER_COMMON_COLUMNS
  ): Observable<ZpxApiGetPassholderCommonColumnsResponse> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      switchMap(([headers, divisionId]) => {
        const params: ZpxApiGetPassholderCommonColumnsParams = {};
        if (divisionId) {
          params.division_id = divisionId;
        }
        const url = this._getPassholderTypeCommonColumnValuesUrl(
          passholderType,
          commonColumn
        );
        return this.httpService.get(
          url,
          new HttpParams({ fromObject: { ...params } }),
          new HttpHeaders(headers)
        );
      }),
      map(
        (response) => response.body as ZpxApiGetPassholderCommonColumnsResponse
      )
    );
  }

  getGroups(): Observable<Group[]> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      filter(([headers]) => headers !== null),
      switchMap(([headers, divisionId]) => {
        let params = null;
        if (divisionId) {
          params = new HttpParams({ fromObject: { division_id: divisionId } });
        }
        return this.httpService
          .get(this.groupsUrl, params, new HttpHeaders(headers))
          .pipe(
            retry(3),
            map((response) => {
              const groups = (response.body as Group[]).sort((a, b) => {
                const _a = a.name.toLowerCase();
                const _b = b.name.toLowerCase();
                if (_a < _b) {
                  return -1;
                }
                if (_a > _b) {
                  return 1;
                }
                return 0;
              });

              return groups;
            })
          );
      })
    );
  }

  getCustomColumnValues(
    customColumndId: string
  ): Observable<ZpxApiGetCustomTypeColumnValuesResponse> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      first(),
      switchMap(([headers, divisionId]) => {
        let params = null;
        if (divisionId) {
          params = new HttpParams({ fromObject: { division_id: divisionId } });
        }
        const url = this._getCustomColumnValuesUrl(customColumndId);
        return this.httpService.get(url, params, new HttpHeaders(headers)).pipe(
          map((response) => {
            return response.body as ZpxApiGetCustomTypeColumnValuesResponse;
          })
        );
      })
    );
  }

  patchPassholder(id: string, patchBody: PassholderPatchBody): Observable<any> {
    const url = `${this.url}/passholder/${id}/extended`;
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        return this.httpClient
          .patch(url, patchBody, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(`Error patching passholder ${id}: ${err}`)
              );
              return throwError(err);
            })
          );
      })
    );
  }

  postPassholder(postBody): Observable<any> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        return this.httpClient
          .post(this.postPassholderUrl, postBody, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error posting passholder ${JSON.stringify(postBody)} `
                )
              );
              return throwError(err);
            })
          );
      })
    );
  }

  patchGroup(id: string, patchBody: GroupBody): Observable<Group> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    const url = `${this.url}/zpx-groups/${id}`;

    return headersAndDivisionId$.pipe(
      filter(([headers, _]) => headers !== null),
      mergeMap(([headers, divisionId]) => {
        const finalUrl = divisionId ? `${url}?division_id=${divisionId}` : url;

        const group$ = this.httpClient.patch(finalUrl, patchBody, {
          headers: new HttpHeaders(headers)
        }) as Observable<Group>;

        return group$.pipe(
          catchError((err) => {
            this.datadogService.addRumError(
              new Error(`Error patching group ${id}: ${err}`)
            );
            return throwError(err);
          })
        );
      })
    );
  }

  getGroupDivisions(groupId: string): Observable<ZpxGroupDivisionExtended[]> {
    const url = this.zpxGroupDivisionUrl;
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);

    return headersAndDivisionId$.pipe(
      filter(([headers, _]) => headers !== null),
      mergeMap(([headers, divisionId]) => {
        const _params = {
          group_id: groupId,
          division_id: divisionId
        };

        const params = new HttpParams({ fromObject: _params });
        return this.httpService.get(url, params, headers as any).pipe(
          map((response) => {
            return response.body as ZpxGroupDivisionExtended[];
          }),
          catchError((err) => {
            this.datadogService.addRumError(
              new Error(
                `Error fetching group divisions for group ${groupId}: ${err}`
              )
            );
            return throwError(err);
          })
        ) as Observable<ZpxGroupDivisionExtended[]>;
      })
    );
  }

  postGroupDivisions(
    postBody: ZpxGroupDivision[]
  ): Observable<ZpxGroupDivisionHttpResponseBody> {
    const url = this.zpxGroupDivisionUrl;
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);

    return headersAndDivisionId$.pipe(
      filter(([headers, _]) => headers !== null),
      mergeMap(([headers, divisionId]) => {
        const finalUrl = divisionId ? `${url}?division_id=${divisionId}` : url;
        const zpxGroupDivisionsPost$ = this.httpService.post(
          finalUrl,
          postBody,
          null,
          headers as any
        ) as Observable<ZpxGroupDivisionHttpResponseBody>;
        return zpxGroupDivisionsPost$.pipe(
          catchError((err) => {
            this.datadogService.addRumError(
              new Error(
                `Error posting group divisions with body ${JSON.stringify(postBody)}: ${err}`
              )
            );
            return throwError(err);
          })
        );
      })
    );
  }

  deleteGroupDivisions(
    body: string[]
  ): Observable<ZpxGroupDivisionHttpResponseBody> {
    const url = `${this.zpxGroupDivisionUrl}-delete`;
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);

    return headersAndDivisionId$.pipe(
      filter(([headers, _]) => headers !== null),
      mergeMap(([headers, divisionId]) => {
        const finalUrl = divisionId ? `${url}?division_id=${divisionId}` : url;

        const zpxGroupDivisionsDelete$ = this.httpService.post(
          finalUrl,
          body,
          null,
          headers as any
        ) as Observable<ZpxGroupDivisionHttpResponseBody>;

        return zpxGroupDivisionsDelete$.pipe(
          catchError((err) => {
            this.datadogService.addRumError(
              new Error(
                `Error deleting group divisions with body ${JSON.stringify(body)}: ${err}`
              )
            );
            return throwError(err);
          })
        );
      })
    );
  }

  deactivatePassholders(passholderIdsBody: string[]) {
    const url = `${this.url}/passholders/deactivate`;

    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersAndDivisionId$.pipe(
      first(),
      switchMap(([headers, divisionId]) => {
        const finalUrl = divisionId ? `${url}?division_id=${divisionId}` : url;
        return this.httpClient
          .patch(finalUrl, passholderIdsBody, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error deactivating passholders with body ${JSON.stringify(passholderIdsBody)}: ${err}`
                )
              );
              return throwError(err);
            })
          );
      })
    );
  }

  postCustomColumn(body: Partial<ZpxApiPostCustomColumnBody>) {
    // we need to standardize this path in the api. everything should be under custom-type-columns (plural)
    // see PUP-5057
    const url = `${this.url}/custom-type-columns`;
    const headersCompanyIdDivisionId = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.selectedCompanyService.getCompanyId(),
      this.appService.selectedDivisionId$
    ]);
    return headersCompanyIdDivisionId.pipe(
      mergeMap(([headers, companyId, divisionId]) => {
        const postBody = { ...body };
        postBody['company_id'] = companyId;
        postBody['division_id'] = divisionId;
        return this.httpClient
          .post(url, postBody, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(`Error posting custom column: ${err}`)
              );
              return throwError(err);
            })
          );
      })
    );
  }

  patchCustomColumn(body: ZpxApiPatchCustomColumnBody, customColumnId: string) {
    // we need to standardize this path in the api. everything should be under custom-type-columns (plural)
    const url = `${this.url}/custom-type-columns/${customColumnId}`;
    return this.authHeadersService.getAuthHeaders().pipe(
      switchMap((headers) => {
        return this.httpClient
          .patch(url, body, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error patching custom column ${customColumnId}: ${err}`
                )
              );
              return throwError(err);
            })
          );
      })
    );
  }

  deleteCustomColumn(customColumnId: string) {
    // we need to standardize this path in the api. everything should be under custom-type-columns (plural)
    const url = `${this.url}/custom-type-columns/${customColumnId}`;
    return this.authHeadersService.getAuthHeaders().pipe(
      switchMap((headers) => {
        return this.httpClient
          .delete(url, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error deleting custom column ${customColumnId}: ${err}`
                )
              );
              return throwError(err);
            })
          );
      })
    );
  }

  validatePassholderImport(
    importCsvFile: File,
    passholderTypeId: string
  ): Observable<ZpxApiValidatePassholderCSVResponse> {
    const url = `${this.url}/csv/passholders/${passholderTypeId}`;

    const formData = new FormData();
    formData.append('passholder_csv', importCsvFile);

    return this.authHeadersService.getAuthHeaders().pipe(
      switchMap((headers) => {
        return this.httpClient
          .patch(url, formData, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(`Error validating passholder import: ${err}`)
              );
              return throwError(err);
            })
          );
      })
    ) as Observable<ZpxApiValidatePassholderCSVResponse>;
  }

  commitPassholderImport(validationId: string): Observable<Object> {
    const url = `${this.url}/csv/passholders/${validationId}/commit`;
    return this.authHeadersService.getAuthHeaders().pipe(
      switchMap((headers) => {
        return this.httpClient
          .patch(url, null, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error commiting passholder import ${validationId}: ${err}`
                )
              );
              return throwError(err);
            })
          );
      })
    );
  }

  getCompleteCsv(passholderTypeId: string): Observable<HttpResponse<string>> {
    const url = `${this.url}/passholders/csv`;
    const headersDivisionId = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);
    return headersDivisionId.pipe(
      mergeMap(([headers, divisionId]) => {
        const _params = {
          passholder_type_id: passholderTypeId,
          division_id: divisionId
        };
        return this.httpClient
          .get(url, {
            headers: new HttpHeaders(headers),
            params: new HttpParams({ fromObject: _params }),
            responseType: 'text',
            observe: 'response'
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error getting passholder csv. passholderTypeId: ${passholderTypeId}: ${err}`
                )
              );
              return throwError(err);
            })
          );
      })
    );
  }

  getPassholderHistory(
    passholderId: string
  ): Observable<ZpxApiPassholderHistoryResponse[]> {
    const url = `${this.url}/passholder/${passholderId}/history`;
    return this.authHeadersService.getAuthHeaders().pipe(
      switchMap((headers) => {
        return this.httpClient
          .get(url, {
            headers: new HttpHeaders(headers)
          })
          .pipe(
            catchError((err) => {
              this.datadogService.addRumError(
                new Error(
                  `Error getting passholder history ${passholderId}: ${err}`
                )
              );
              return throwError(err);
            })
          );
      })
    ) as Observable<ZpxApiPassholderHistoryResponse[]>;
  }

  postGroup(postGroupBody: GroupBody): Observable<Group> {
    const headersCompanyDiv$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.selectedCompanyService.getCompanyId(),
      this.appService.selectedDivisionId$
    ]);
    return headersCompanyDiv$.pipe(
      mergeMap(([headers, companyId, divisionId]) => {
        const url = `${this.groupsUrl}?company_id=${companyId}&division_id=${divisionId}`;
        const _postGroup$ = this.httpClient.post(url, postGroupBody, {
          headers: new HttpHeaders(headers)
        }) as Observable<Group>;
        return _postGroup$.pipe(
          catchError((err) => {
            this.datadogService.addRumError(err);
            return throwError(err);
          })
        );
      })
    );
  }

  getEventsReport(
    eventsBody: ZpxApiEventGetReportBodyParams = {}
  ): Observable<ZpxApiEventGetReportHttpResponseBody> {
    const headersAndDivisionId$ = combineLatest([
      this.authHeadersService.getAuthHeaders(),
      this.appService.selectedDivisionId$
    ]);

    return headersAndDivisionId$.pipe(
      switchMap(([headers, divisionId]) => {
        eventsBody.division_id = divisionId;
        const _getEvents$ = this.httpClient.post(
          this.eventsReportUrl,
          eventsBody,
          {
            headers: new HttpHeaders(headers)
          }
        ) as Observable<ZpxApiEventGetReportHttpResponseBody>;
        return _getEvents$.pipe(
          catchError((err) => {
            this.datadogService.addRumError(err);
            return throwError(err);
          })
        );
      })
    );
  }
}
