import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import { FilterDropdownComponent } from '@src/app/components/filter-dropdown/filter-dropdown.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { makeDropdownOptions } from '@src/app/shared/utilities/utilities';
import { AppService } from '@src/app/app.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-division-picker-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FilterDropdownComponent],
  templateUrl: './division-picker-modal.component.html',
  styleUrls: ['./division-picker-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DivisionPickerModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<DivisionPickerModalComponent>,
    private appService: AppService
  ) {}

  private onDestroy$ = new Subject<void>();

  data = inject<any>(MAT_DIALOG_DATA);

  label = 'Division';
  options = [];
  isMultiple = false;
  formGroup = new FormGroup({});
  selectOptionsControlName = 'division_id';
  selectedDivisonId = null;

  setOptions(): void {
    this.options = makeDropdownOptions(this.data.divisions, 'name', 'id');
  }

  ngOnInit(): void {
    this.setOptions();
    this.formGroup.addControl(this.selectOptionsControlName, new FormControl());

    this.formGroup.controls[this.selectOptionsControlName].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((divisionId) => {
        this.selectedDivisonId = divisionId;
      });
  }

  onApply() {
    if (this.selectedDivisonId) {
      this.appService.selectedDivisionId$.next(this.selectedDivisonId);
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
