import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  Validators
} from '@angular/forms';
import { ZpxInputComponent } from '../zpx-input/zpx-input.component';
import { FormsModule } from '@angular/forms';
import {
  CustomTypeColumn,
  PASSHOLDER_TYPES,
  PassholderForTable,
  PassholderPatchBody,
  ZpxApiPatchParamNames
} from '@src/app/models/zpx-api.model';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StatusFilterComponent } from '../status-filter/status-filter.component';
import { PassholderTypeFilterComponent } from '../passholder-type-filter/passholder-type-filter.component';
import { PassholderGroupFilterComponent } from '../passholder-group-filter/passholder-group-filter.component';
import { AppService } from '@src/app/app.service';
import { PassholderFormState } from './passholder-filter-bar.model';
import _ from 'lodash-es';
import { uniqueIdValidator } from './passholder-validators';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { ManagePassholdersService } from '@src/app/services/manage-passholders/manage-passholders.service';

@Component({
  selector: 'app-passholder-filter-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ZpxInputComponent,
    StatusFilterComponent,
    PassholderTypeFilterComponent,
    PassholderGroupFilterComponent
  ],
  templateUrl: './passholder-filter-bar.component.html',
  styleUrls: ['./passholder-filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassholderFilterBarComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private zpxApiService: ZpxApiService,
    private managePassholdersService: ManagePassholdersService
  ) {}

  private onDestroy$ = new Subject<void>();
  private _passholder: PassholderForTable;
  @Output() sendPatchBody: EventEmitter<PassholderPatchBody> = new EventEmitter(
    null
  );
  @Output() sendPostBody: EventEmitter<any> = new EventEmitter(null);
  @Output() formStatus: EventEmitter<FormControlStatus> = new EventEmitter(
    null
  );
  @Input() action = null;
  @Input() passholderType: PASSHOLDER_TYPES;
  @Input() set passholder(p: PassholderForTable) {
    this._passholder = p;
    if (p) {
      this.statusDefaultOption = p.active ? '1' : '0';
      this.standardInputs[ZpxApiPatchParamNames.PASS_NUMBER].control.setValue(
        p.card_number
      );
      this.standardInputs[ZpxApiPatchParamNames.LAST_NAME].control.setValue(
        p.last_name
      );
      this.standardInputs[ZpxApiPatchParamNames.FIRST_NAME].control.setValue(
        p.first_name
      );
      this.standardInputs['unique_id'].control.setValue(p.unique_id);
      this.standardInputs['unique_id'].control.disable(); // this control is disabled when editing a passholder
    }
  }
  get passholder(): PassholderForTable {
    return this._passholder;
  }

  statusDefaultOption = '1'; // default to Active
  patchBody: PassholderPatchBody = null;
  customColumns: CustomTypeColumn[] = null;

  formGroup: FormGroup = this.fb.group({});

  standardInputs = {
    [ZpxApiPatchParamNames.PASS_NUMBER]: {
      label: 'Card No.',
      control: new FormControl('', [Validators.required])
    },
    [ZpxApiPatchParamNames.LAST_NAME]: {
      label: 'Last Name',
      control: new FormControl('', [Validators.required])
    },
    [ZpxApiPatchParamNames.FIRST_NAME]: {
      label: 'First Name',
      control: new FormControl('', [Validators.required])
    },
    unique_id: {
      label: 'Unique Id',
      control: new FormControl(
        '',
        Validators.required,
        uniqueIdValidator(this.zpxApiService)
      )
    }
  };

  typeDefaultOption$ = this.managePassholdersService.selectedPassholderTypeId$;
  customColumnInputs$;
  groupDefaultOption$ = this.appService.groupsByNameAsc$.pipe(
    map((groups) => {
      // Editing a passholder
      if (this.passholder) {
        return groups.find((group) => group.name === this.passholder.group_name)
          .id;
      }
      // Adding a passholder ...if we want a default value, we can comment the below in
      // return groups[0].id;
    })
  );

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.customColumnInputs$ = this.appService.selectedDivisionId$.pipe(
      switchMap((divId) =>
        this.managePassholdersService
          .getCustomColumns(divId, this.passholderType)
          .pipe(
            map((customCols) => {
              const colInputs = {};
              customCols.forEach((cc) => {
                let controlVal = '';
                // passholder will exist for editing, not for adding
                if (this.passholder) {
                  controlVal = this.passholder[cc.name]
                    ? this.passholder[cc.name]
                    : '';
                }
                colInputs[`custCol_${cc.id}`] = {
                  label: cc.name,
                  control: new FormControl(controlVal)
                };
              });
              return colInputs;
            })
          )
      )
    );
    this.formGroup.statusChanges
      .pipe(
        debounceTime(20),
        map((status: FormControlStatus) => {
          this.formStatus.emit(status);
          if (status === 'VALID') {
            const requestBody = this.formStateToRequestBody(
              this.formGroup.value,
              this.action
            );
            if (this.action === 'edit') {
              this.sendPatchBody.emit(requestBody);
            }
            if (this.action === 'add') {
              this.sendPostBody.emit(requestBody);
            }
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // this is a really annoying hack because the pattern library SearchableDropdownComponent adds its own
    // control even when you try very hard for it to not.
    // https://gitlab.com/ZonarSystems/pattern-library/zonar-ui-searchable-dropdown/-/blob/main/projects/zonar-ui-searchable-dropdown/README.md
    // search 'searchFormControlName'
    this.formGroup.removeControl('undefined');
  }

  markAllAsTouched() {
    this.formGroup.markAllAsTouched();
  }

  formStateToRequestBody(formState: PassholderFormState, requestAction) {
    const standardKeys = [
      'passholder_type_id',
      'first_name',
      'last_name',
      'zpx_group_id',
      'pass_number'
    ];

    const requestBody = { ..._.pick(formState, standardKeys) };
    const specialMappingRequired = _.omit(formState, standardKeys);
    _.forEach(specialMappingRequired, (val, key) => {
      if (key == 'active') {
        requestBody['active'] = Boolean(parseInt(val));
      }
      // Adding a passholder requires exsid/unique_id
      if (requestAction === 'add') {
        if (key == 'unique_id') {
          requestBody['exsid'] = val;
        }
      }
      if (key.includes('custCol_')) {
        if (requestAction == 'edit') {
          // Ensure that custom_type_values_update array exists
          requestBody.custom_type_values_update =
            requestBody.custom_type_values_update || [];
          const custColId = key.split('custCol_')[1];
          requestBody.custom_type_values_update.push({
            column_id: custColId,
            value: val
          });
        }
        if (requestAction == 'add') {
          // Ensure that custom_type_values array exists
          requestBody.custom_type_values = requestBody.custom_type_values || [];
          const custColId = key.split('custCol_')[1];
          requestBody.custom_type_values.push({
            column_id: custColId,
            value: val
          });
        }
      }
    });
    return requestBody;
  }
}
