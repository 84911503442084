import { Injectable } from '@angular/core';
import { AppService } from '@src/app/app.service';
import {
  PASSHOLDER_COMMON_COLUMNS,
  PASSHOLDER_TYPES
} from '@src/app/models/zpx-api.model';
import { makeDropdownOptions } from '@src/app/shared/utilities/utilities';

import {
  DropdownOptionsObject,
  FILTER_TYPE,
  SearchableDropdownModel
} from '@zonar-ui/filter';

import { BehaviorSubject, forkJoin, merge } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import {
  COMMON_COLUMN_TYPES,
  ZpxApiEventGetReportBodyParams,
  ZPX_EVENT_PARAM_NAMES,
  EVENT_TYPES
} from '../models/events.model';

import _ from 'lodash-es';
import { ManagePassholdersService } from '@src/app/services/manage-passholders/manage-passholders.service';

@Injectable({
  providedIn: 'root'
})
export class EventsFilterBarService {
  constructor(
    private appService: AppService,
    private managePassholdersService: ManagePassholdersService
  ) {}

  _dateRangePresets = [
    {
      title: 'Today',
      value: 'today',
      numValue: 0
    },
    {
      title: 'Previous 7 Days',
      value: 'previous-7-days',
      numValue: 7
    },
    {
      title: 'Previous 14 Days',
      value: 'previous-14-days',
      numValue: 14
    },
    {
      title: 'Previous 30 Days',
      value: 'previous-30-days',
      numValue: 30
    },
    {
      title: 'Previous 365 Days',
      value: 'previous-365-days',
      numValue: 365
    },
    {
      title: 'Custom',
      value: 'custom',
      numValue: undefined
    }
  ];

  displayedOptions = {
    divisionNameOptions$: new BehaviorSubject([] as DropdownOptionsObject[]),
    lastName$: new BehaviorSubject([] as DropdownOptionsObject[]),
    firstName$: new BehaviorSubject([] as DropdownOptionsObject[]),
    groups$: new BehaviorSubject([] as DropdownOptionsObject[]),

    cardholderType$: this.appService.passholderTypes$.pipe(
      filter((p) => p !== null),
      first(),
      map((passholderTypes) => {
        return makeDropdownOptions(passholderTypes, 'name', 'id');
      })
    ),
    showIgnoredEvents$: new BehaviorSubject([
      { title: 'True', value: 'true' },
      { title: 'False', value: 'false' }
    ] as DropdownOptionsObject[]),

    uniqueId$: new BehaviorSubject([] as DropdownOptionsObject[]),
    eventType$: new BehaviorSubject([
      { title: EVENT_TYPES.CREATED, value: EVENT_TYPES.CREATED },
      { title: EVENT_TYPES.EDITED, value: EVENT_TYPES.EDITED },
      { title: EVENT_TYPES.INACTIVE, value: EVENT_TYPES.INACTIVE },
      { title: EVENT_TYPES.INCOMPLETE, value: EVENT_TYPES.INCOMPLETE },
      { title: EVENT_TYPES.NORMAL, value: EVENT_TYPES.NORMAL },
      { title: EVENT_TYPES.UNASSIGNED, value: EVENT_TYPES.UNASSIGNED }
    ]),

    assetNo$: this.appService.assets$.pipe(
      filter((a) => a !== null),
      map((assets) => {
        return makeDropdownOptions(assets, 'name', 'name');
      })
    )
  };

  // This is used to maintain the state of the user-selected filter options
  filterBody$: BehaviorSubject<ZpxApiEventGetReportBodyParams> =
    new BehaviorSubject(null);

  // this is currently just used in mobile implementation to reset the pagination page to handle infinite scrolling
  eventFilterChanged$: BehaviorSubject<boolean> = new BehaviorSubject(
    undefined
  );

  private commonDropdownProps = {
    isMultiple: true,
    blueCheckmarks: true,
    inputParams: []
  };

  public getDivisonModel(divisionId: string): SearchableDropdownModel {
    return {
      type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
      options: {
        blueCheckmarks: false,
        inputParams: [],
        label: 'Division Name',
        data: this.displayedOptions.divisionNameOptions$,
        fgControlName: ZPX_EVENT_PARAM_NAMES.DIVISION_ID,
        valueType: 'string',
        enableAllOptions: false,
        paramName: ZPX_EVENT_PARAM_NAMES.DIVISION_ID,
        defaultValue: divisionId,
        isMultiple: false
      }
    };
  }

  public getCommonColumnsFilters(): any {
    return [
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          ...this.commonDropdownProps,
          label: 'Division Name',
          data: this.displayedOptions.divisionNameOptions$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.DIVISION_ID,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.DIVISION_ID,
          isMultiple: false
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Last Name',
          data: this.displayedOptions.lastName$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.LAST_NAMES,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.LAST_NAMES,
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'First Name',
          data: this.displayedOptions.firstName$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.FIRST_NAMES,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.FIRST_NAMES,
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.DATE_RANGE,
        options: {
          datePreset: this._dateRangePresets,
          defaultVal: 0,
          selectedDateFilter: null,
          paramName: [
            ZPX_EVENT_PARAM_NAMES.START_TIME,
            ZPX_EVENT_PARAM_NAMES.END_TIME
          ],
          monthsBackRestricted: 36,
          showTimeRangeSelector: true
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Group',
          isMultiple: true,
          data: this.displayedOptions.groups$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.GROUPS,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.GROUPS,
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Cardholder Type',
          data: this.displayedOptions.cardholderType$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.PASSHOLDER_TYPE_IDS,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.PASSHOLDER_TYPE_IDS,
          ...this.commonDropdownProps
        }
      },

      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Show Ignored Events.',
          data: this.displayedOptions.showIgnoredEvents$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED,
          valueType: 'string',
          enableAllOptions: false,
          defaultValue: 'false',
          paramName: ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED,
          isMultiple: false,
          inputParams: []
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Unique Id',
          data: this.displayedOptions.uniqueId$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.UNIQUE_IDS,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.UNIQUE_IDS,
          ...this.commonDropdownProps
        }
      },

      // Asset not a thing in the API yet
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Asset No',
          data: this.displayedOptions.assetNo$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.ASSET_NO,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.ASSET_NO,
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Event Type',
          data: this.displayedOptions.eventType$,
          fgControlName: ZPX_EVENT_PARAM_NAMES.EVENT_FLAGS,
          valueType: 'string',
          enableAllOptions: false,
          paramName: ZPX_EVENT_PARAM_NAMES.EVENT_FLAGS,
          ...this.commonDropdownProps
        }
      }
    ];
  }

  parseUserSelectedFilters(selectedFilterObj: ZpxApiEventGetReportBodyParams) {
    const filterableKeys = [
      ZPX_EVENT_PARAM_NAMES.FIRST_NAMES,
      ZPX_EVENT_PARAM_NAMES.LAST_NAMES,
      ZPX_EVENT_PARAM_NAMES.UNIQUE_IDS,
      ZPX_EVENT_PARAM_NAMES.GROUPS,
      ZPX_EVENT_PARAM_NAMES.EVENT_FLAGS,
      ZPX_EVENT_PARAM_NAMES.PASSHOLDER_TYPE_IDS,
      ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED,
      ZPX_EVENT_PARAM_NAMES.ASSET_NO,
      ZPX_EVENT_PARAM_NAMES.START_TIME,
      ZPX_EVENT_PARAM_NAMES.END_TIME
    ];
    const formattedBody = { ..._.pick(selectedFilterObj, filterableKeys) };

    this.filterBody$.next(formattedBody);
  }

  private _convertToDropdownObjects(arr: string[]): DropdownOptionsObject[] {
    if (arr) {
      return arr
        .map((x) => ({ title: x, value: x }))
        .sort((a, b) => {
          const _a = a.title.toLowerCase();
          const _b = b.title.toLowerCase();
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        });
    }
  }
  public setCommonColumnFiltersOptions() {
    const passholderTypesArray = [
      PASSHOLDER_TYPES.AIDE,
      PASSHOLDER_TYPES.STUDENT,
      PASSHOLDER_TYPES.DRIVER
    ];

    const fNameObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) => {
        const allPassholderTypeFirstNameObs$ = passholderTypesArray.map(
          (pType) =>
            this.managePassholdersService
              .getPassholderCommonColumnValues(
                divId,
                pType,
                PASSHOLDER_COMMON_COLUMNS.FIRST_NAMES
              )
              .pipe(first())
        );
        return forkJoin(allPassholderTypeFirstNameObs$).pipe(
          map((resp) => {
            const firstNameOptions = this._convertToDropdownObjects(
              resp.map((p) => p.first_names).flat()
            );
            this.displayedOptions.firstName$.next(firstNameOptions);
          })
        );
      })
    );

    const lNameObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) => {
        const allPassholderTypeLastNameObs$ = passholderTypesArray.map(
          (pType) =>
            this.managePassholdersService
              .getPassholderCommonColumnValues(
                divId,
                pType,
                PASSHOLDER_COMMON_COLUMNS.LAST_NAMES
              )
              .pipe(first())
        );
        return forkJoin(allPassholderTypeLastNameObs$).pipe(
          map((resp) => {
            const lastNameOptions = this._convertToDropdownObjects(
              resp.map((p) => p.last_names).flat()
            );
            this.displayedOptions.lastName$.next(lastNameOptions);
          })
        );
      })
    );

    const uniqueIdObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) => {
        const allPassholderTypeUniqueIdsObs$ = passholderTypesArray.map(
          (pType) =>
            this.managePassholdersService
              .getPassholderCommonColumnValues(
                divId,
                pType,
                PASSHOLDER_COMMON_COLUMNS.EXSIDS
              )
              .pipe(first())
        );
        return forkJoin(allPassholderTypeUniqueIdsObs$).pipe(
          map((resp) => {
            const uniqueIdOptions = this._convertToDropdownObjects(
              resp.map((p) => p.exsids).flat()
            );
            this.displayedOptions.uniqueId$.next(uniqueIdOptions);
          })
        );
      })
    );

    // TODO This probably only needs to be called once, since groups span across passholder types
    const groupsObs$ = this.appService.groupsByNameAsc$.pipe(
      filter((groups) => groups !== null),
      map((groups) => {
        const groupOptions = groups
          .filter((group) => group.active)
          .map((group) => ({
            title: group.name,
            value: group.id
          }));
        this.displayedOptions.groups$.next(groupOptions);
      })
    );

    const divisionObs$ = this.appService.divisions$.pipe(
      filter((divs) => divs !== null),
      map((divs) => {
        const divOptions = makeDropdownOptions(divs, 'name', 'id');
        this.displayedOptions.divisionNameOptions$.next(divOptions);
      })
    );
    const colFiltersMap = {
      firstNames: {
        obs: fNameObs$,
        options: this.displayedOptions.firstName$
      },
      lastNames: {
        obs: lNameObs$,
        options: this.displayedOptions.lastName$
      },
      uniqueIds: {
        obs: uniqueIdObs$,
        options: this.displayedOptions.uniqueId$
      },
      groups: {
        obs: groupsObs$,
        options: this.displayedOptions.groups$
      },
      divisons: {
        obs: divisionObs$,
        options: this.displayedOptions.divisionNameOptions$
      }
    };

    const getCommonColOptionsObss$ = [];
    Object.keys(colFiltersMap).forEach((k: COMMON_COLUMN_TYPES) => {
      getCommonColOptionsObss$.push(colFiltersMap[k].obs);
    });

    // Run requests in parallel
    return merge(...getCommonColOptionsObss$);
  }
}
