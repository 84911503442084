import { Component, inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {
  PASSHOLDER_TYPES,
  PassholderPatchBody,
  PassholderForTable
} from '@src/app/models/zpx-api.model';

import { PassholderFilterBarComponent } from '../../passholder-filter-bar/passholder-filter-bar.component';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { AppService } from '@src/app/app.service';
import { SelectedCompanyService } from '@services/selected-company/selected-company.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  switchMap
} from 'rxjs/operators';
import { FormControlStatus } from '@angular/forms';
import { EventsTableComponent } from '@src/app/views/events/events-table/events-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ZuiDatePickerModule } from '@zonar-ui/date-picker';
import { PassholderHistoryTableComponent } from '../../passholder-history-table/passholder-history-table.component';
import { EventsFilterBarService } from '@src/app/views/events/services/events-filter-bar.service';
import { RouterModule } from '@angular/router';
import { ManagePassholdersService } from '@src/app/services/manage-passholders/manage-passholders.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-passholder-modal',
  standalone: true,

  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    FlexLayoutModule,
    PassholderFilterBarComponent,
    MatIconModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    FormsModule,
    ZuiDatePickerModule,
    EventsTableComponent,
    PassholderHistoryTableComponent,
    RouterModule
  ],
  templateUrl: './passholder-modal.component.html',
  styleUrls: ['./passholder-modal.component.scss']
})
export class PassholderModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<PassholderModalComponent>,
    private zpxApiService: ZpxApiService,
    private dataDogService: DataDogService,
    private appService: AppService,
    private selectedCompanyService: SelectedCompanyService,
    private managePassholdersService: ManagePassholdersService,
    private eventsFilterBarServie: EventsFilterBarService
  ) {}

  data: {
    passholder: PassholderForTable;
    passholderType: PASSHOLDER_TYPES;
    action: string;
  } = inject(MAT_DIALOG_DATA);

  passHolderEventSearchDate = '';
  patchBody: PassholderPatchBody;
  postBody;
  validForm = new BehaviorSubject(false);
  saveInProgress: boolean;

  @ViewChild(PassholderFilterBarComponent)
  passholderFilterBar: PassholderFilterBarComponent;

  hasEditPermission$ = this.selectedCompanyService
    .getUserPermissions()
    .pipe(
      map((permissionValues: string[]) =>
        permissionValues.includes('zpass:edit:passholder')
      )
    );

  saveDisabled = combineLatest([this.validForm, this.hasEditPermission$]).pipe(
    distinctUntilChanged(),
    map((arr) => arr.some((v) => v !== true))
  );

  ngOnInit(): void {
    // emitting this observable out will get events specific to this user
    this.eventsFilterBarServie.filterBody$.next({
      exsids: [this.data.passholder.unique_id],
      first_names: [this.data.passholder.first_name],
      last_names: [this.data.passholder.last_name]
    });
  }

  ngOnDestroy(): void {
    // we want to reset filters if someone travels to /events from here so they can get all data from the events table. Otherwise, they would just get the events specific to this current user
    this.eventsFilterBarServie.filterBody$.next({});
  }

  getPatchBody(body: PassholderPatchBody) {
    this.patchBody = body;
  }

  getPostBody(body) {
    this.postBody = body;
  }

  performPassholderAction() {
    if (this.validForm.value) {
      if (this.data.action == 'edit') {
        this.saveInProgress = true;
        this._patchPassholder()
          .pipe(first())
          .subscribe(() => {
            this.dialogRef.close();
            this.saveInProgress = false;
          });
      }
      if (this.data.action == 'add') {
        this.saveInProgress = true;
        this._postPassholder()
          .pipe(first())
          .subscribe(() => {
            this.dialogRef.close();
            this.saveInProgress = false;
          });
      }
    } else {
      this.passholderFilterBar.markAllAsTouched();
    }
  }

  formStatus(formStatus: FormControlStatus) {
    formStatus === 'VALID'
      ? this.validForm.next(true)
      : this.validForm.next(false);
  }

  _postPassholder() {
    return combineLatest([
      this.selectedCompanyService.getCompanyId(),
      this.appService.selectedDivisionId$
    ]).pipe(
      switchMap(([companyId, divisionId]) => {
        this.postBody = {
          ...this.postBody,
          company_id: companyId,
          division_id: divisionId
        };
        return this.zpxApiService.postPassholder(this.postBody);
      }),
      switchMap(() => this.managePassholdersService.selectedPassholderType$),
      map((passholderType: PASSHOLDER_TYPES) =>
        this._refreshAfterSave(passholderType)
      ),
      catchError((error) => {
        this.dataDogService.addRumError(error);
        console.error(error);
        return of(null);
      })
    );
  }

  _patchPassholder() {
    return this.zpxApiService
      .patchPassholder(this.data.passholder.zpx_id, this.patchBody)
      .pipe(
        switchMap(() => this.managePassholdersService.selectedPassholderType$),
        map((passholderType: PASSHOLDER_TYPES) =>
          this._refreshAfterSave(passholderType)
        ),
        catchError((error) => {
          this.dataDogService.addRumError(error);
          console.error(error);
          return of(null);
        })
      );
  }

  _refreshAfterSave(passholderType: PASSHOLDER_TYPES) {
    this.managePassholdersService.refreshPassholders(passholderType);
    this.managePassholdersService.refreshAllCommonColumnValues();
    this.managePassholdersService.refreshAllCustomColumnValues();
  }
  filterEvents() {
    //TODO Take the user to the filtered events page
  }
}
