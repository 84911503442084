import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventsTableDataSource } from './events-table.datasource';
import { ZonarUITableModule } from '@zonar-ui/table';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ZpxTableComponent } from '@src/app/components/zpx-table/zpx-table.component';
import { MatIconModule } from '@angular/material/icon';
import { EventDetailsModalComponent } from '@components/modals/event-details-modal/event-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ZpxEventForTable } from '@app/views/events/models/events.model';
import { AppService } from '@src/app/app.service';

@Component({
  selector: 'app-passholder-events-table',
  standalone: true,
  providers: [EventsTableDataSource],
  imports: [CommonModule, ZonarUITableModule, ZpxTableComponent, MatIconModule],
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventsTableComponent implements OnInit, OnDestroy {
  constructor(
    private eventsDatasSource: EventsTableDataSource,
    private getEnvService: GetEnvironmentService,
    private dialog: MatDialog,
    private appService: AppService
  ) {}

  @Input() showCheckmarks = false;
  private onDestroy$ = new Subject<void>();
  columns$ = new BehaviorSubject([]);
  dataSource = this.eventsDatasSource;
  offset = 0;
  currentPage: number = 1;

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  totalResults: number = 0;

  @Input() enableMobileTable = true;
  @Input() showTableHeader = true;

  ngOnInit(): void {
    const standardColumns = this.dataSource.getStandardColumns(
      this.showCheckmarks
    );
    this.columns$.next(standardColumns);

    this.appService
      .shouldLoadData$()
      .pipe(
        filter((loadData) => Boolean(loadData)),
        switchMap(() => this.dataSource.populateEventsTable()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    // TODO -- see if this is still necessary
    this.dataSource.companySwitcherBugFixObs$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }
  openEventDetailsDialog(event) {
    this.dialog.open(EventDetailsModalComponent, {
      data: {
        event
      },
      width: '70vw',
      height: '85vh'
    });
  }

  onClick(event: ZpxEventForTable) {
    if (event.class.includes('material-icons')) {
      this.openEventDetailsDialog(event);
    }
  }
  ngOnDestroy(): void {
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.columns$.complete();
  }
}
