import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { first, map, take } from 'rxjs/operators';
import {
  PassholderForTable,
  ZpxApiPassholderHistoryResponse
} from '@src/app/models/zpx-api.model';
import { formatDate } from '@src/app/shared/utilities/utilities';

@Component({
  selector: 'app-passholder-history-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule],
  templateUrl: './passholder-history-table.component.html',
  styleUrls: ['./passholder-history-table.component.scss']
})
export class PassholderHistoryTableComponent implements OnInit, AfterViewInit {
  @Input() passholder: PassholderForTable;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private zpxApiService: ZpxApiService) {}

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = [
    'date',
    'time',
    'editedBy',
    'editedField',
    'oldValue',
    'newValue'
  ];

  ngOnInit(): void {
    this._populatePassholderHistoryDatasource();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  _populatePassholderHistoryDatasource() {
    this.zpxApiService
      .getPassholderHistory(this.passholder.zpx_id)
      .pipe(
        map((resp) => {
          resp.sort((a, b) => {
            return (
              new Date(b.event_ts).valueOf() - new Date(a.event_ts).valueOf()
            );
          });

          const passholderRecords = resp.map((historyRecord) => {
            return {
              date: formatDate(new Date(historyRecord.event_ts)),
              time: formatDate(new Date(historyRecord.event_ts), 'h:m:s A'),
              editedBy: historyRecord.edited_by,
              editedField: historyRecord.edited_field,
              oldValue: historyRecord.old_value,
              newValue: historyRecord.new_value
            };
          });
          this.dataSource.data = passholderRecords;
        }),
        first()
      )
      .subscribe();
  }
}
