import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableModule
} from '@zonar-ui/table';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-zpx-table',
  standalone: true,
  imports: [CommonModule, ZonarUITableModule, MatIconModule],
  templateUrl: './zpx-table.component.html',
  styleUrls: ['./zpx-table.component.scss']
})
export class ZpxTableComponent implements AfterViewInit {
  constructor(
    private getEnvService: GetEnvironmentService,
    public dialog: MatDialog,
    private elementRef: ElementRef
  ) {}

  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() selectedRows: EventEmitter<any> = new EventEmitter();
  @Input() title: string = 'My Table';
  @Input() columns: BehaviorSubject<ZonarUITableModel[]> = new BehaviorSubject(
    []
  );
  @Input() dataSource: ZonarUITableDataSource;
  @Input() initialPageSize: number =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  @Input() uniqueRowIdentifier: string | number = null;
  @Input() enableMobileTable: boolean = false;

  @Input() showTableHeader = true;

  click(event) {
    this.rowClick.emit(event);
  }

  select(event) {
    this.selectedRows.emit(event);
  }

  ngAfterViewInit(): void {
    // handles the case of showing the events table on patch passholder modal without header/buttons
    if (!this.showTableHeader) {
      const header =
        this.elementRef.nativeElement.querySelector('zui-table-header');
      header.style.display = 'none';
    } else {
      // This is kind of hacky, and is necessitated by the zui-table always including the row count
      // without the option of removal (if title is provided)
      // See https://gitlab.com/ZonarSystems/pattern-library/zonar-ui-table/-/blob/main/projects/zonar-ui-table/src/lib/zonar-ui-table.component.html#L29
      // https://gitlab.com/ZonarSystems/pattern-library/zonar-ui-table/-/blob/main/projects/zonar-ui-table/README.md
      // "Title is an optional string containing the title of the table. If missing no title will be added to the table.
      // If present, the title, along with a record count, will be displayed."
      const tableTitleHeader =
        this.elementRef.nativeElement.querySelector('.table-title') ||
        this.elementRef.nativeElement.querySelector('.zui-mobile-table-title');

      const title = tableTitleHeader.innerText.trim();

      const modifiedTitle = title.slice(0, title.indexOf('('));

      tableTitleHeader.innerText = modifiedTitle.trim();
    }
  }
}
