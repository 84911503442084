import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';

import { FilterToggleComponent } from '@components/filter-toggle/filter-toggle.component';
import { PassholderTableComponent } from '@src/app/components/passholder-table/passholder-table.component';
import { TablePassholdersFilterBarComponent } from '@src/app/components/table-passholders-filter-bar/table-passholders-filter-bar.component';

import { DeviceDetectionService } from '@services/device-detection/device-detection.service';

import { PASSHOLDER_TYPES } from '@src/app/models/zpx-api.model';
import { ManagePassholdersService } from '@src/app/services/manage-passholders/manage-passholders.service';

@Component({
  selector: 'app-manage-passholders',
  standalone: true,
  imports: [
    CommonModule,
    FilterToggleComponent,
    PassholderTableComponent,
    TablePassholdersFilterBarComponent,
    MatTabsModule
  ],
  templateUrl: './manage-passholders.component.html',
  styleUrls: ['./manage-passholders.component.scss']
})
export class ManagePassholdersComponent implements OnInit {
  toggleFilterBarDisplay = true;

  mobileViewClasses = {
    'mobile-view': this.device.isMobile ? true : false,
    hidden: false
  };

  constructor(
    public device: DeviceDetectionService,
    private managePassholdersService: ManagePassholdersService
  ) {}

  tabIndexPassholderTypeMap = {
    0: PASSHOLDER_TYPES.STUDENT,
    1: PASSHOLDER_TYPES.AIDE,
    2: PASSHOLDER_TYPES.DRIVER
  };

  ngOnInit(): void {
    // Because this is the parent component for all Passholder Management, we
    // can control the selected passholder type through passholder type tab
    // changes, which in turn flow into changes to the selectedPassholderTypeId,
    // customColumns, etc. in the ManagePassholdersService
    this.managePassholdersService.setSelectedPassholderType(
      PASSHOLDER_TYPES.STUDENT
    );
  }

  mobileDisplayHandler(filtersDisplayed: boolean) {
    this.mobileViewClasses.hidden = filtersDisplayed ? true : false;
  }

  onPassholdersMobileFilterToggled(event): void {
    this.toggleFilterBarDisplay = !this.toggleFilterBarDisplay;
  }
  changePassholder(event: MatTabChangeEvent) {
    this.managePassholdersService.setSelectedPassholderType(
      this.tabIndexPassholderTypeMap[event.index]
    );
  }
}
