<div id="confirm-deactivation-modal">
  <mat-dialog-content>
    <h3>Deactivate Cardholder</h3>
    <p>
      You are about to deactivate {{ data?.selectedPassholderIds?.length }} {{
      setCardholderString() }}.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="button-container">
      <button
        (click)="dialogRef.close()"
        class="mat-stroked-button mat-button-base white-button">
        Cancel
      </button>
      <button
        [disabled]="saveInProgress"
        (click)="confirmDeactivation()"
        class="mat-stroked-button mat-button-base accent-button">
        Confirm
      </button>
    </div>
  </mat-dialog-actions>
</div>
