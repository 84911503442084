import { Component, inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { AppService } from '@src/app/app.service';
import { ManagePassholdersService } from '@src/app/services/manage-passholders/manage-passholders.service';
import { PASSHOLDER_TYPES } from '@src/app/models/zpx-api.model';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-confirm-deactivation-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './confirm-deactivation-modal.component.html',
  styleUrls: ['./confirm-deactivation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDeactivationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeactivationModalComponent>,
    private zpxApiService: ZpxApiService,
    private managePassholdersService: ManagePassholdersService,
    private dataDogService: DataDogService
  ) {}

  data = inject<{ selectedPassholderIds: string[] }>(MAT_DIALOG_DATA);
  saveInProgress: boolean;

  setCardholderString(): string {
    return this.data.selectedPassholderIds.length === 1
      ? 'cardholder'
      : 'cardholders';
  }

  confirmDeactivation() {
    this.saveInProgress = true;
    this.zpxApiService
      .deactivatePassholders(this.data.selectedPassholderIds)
      .pipe(
        switchMap(() => this.managePassholdersService.selectedPassholderType$),
        map((passholderType: PASSHOLDER_TYPES) =>
          this.managePassholdersService.refreshPassholders(passholderType)
        ),
        first(),
        catchError((error) => {
          this.dataDogService.addRumError(error);
          console.error(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.saveInProgress = false;
        this.dialogRef.close();
      });
  }
}
