import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Group, PassholderType } from './models/zpx-api.model';
import { LEGACY_TYPES, LegacyLocation } from './models/entity-api.model';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { PermissionsService } from '@zonar-ui/auth';
import { UserCompany } from './models/user.company.model';
import { IDivisionMap } from '@zonar-ui/auth/lib/models/company.model';

/*

This is the highest level service that is used to share top level information via Behavior Subjects across the app for information that any component or other service needs without relying on deeply nested inputs/outputs or sending Behavior subjects from weird places

The "top level information" state should be those types of data objects that are needed in both the Manage Passholders and Events report views

The initial setting of these shared values should happen in the top-most / highest-level component when the data becomes available.

For example, the first member for this service is passholderType$ to share all the passholder types across the app, as different places need to know the types in order to render elements or fetch items specific to the individual types therein. 

This specific Behavior Subject is only set once, during the AppComponent OnInit hook. However, it is subscribed to extensively in both Manage Passholder and Events related components.

If a new value is published to any of these Behavior Subjects throughout the app, recognize that cascading effects will likely occur through all the existing subscriptions.

This service also includes some utility methods related to these top level objects that are useful throughout the entire application, such as shouldLoadData(), which is used as a control flow.

Please use this service accordingly for other top level information, and be especially mindful about where and when values are set. 

*/
@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private permissionsService: PermissionsService) {}
  passholderTypes$: BehaviorSubject<PassholderType[]> = new BehaviorSubject(
    null
  );

  groupsByNameAsc$: BehaviorSubject<Group[]> = new BehaviorSubject(null);

  selectedDivisionId$: BehaviorSubject<string> = new BehaviorSubject(null);

  divisions$: BehaviorSubject<{ name: string; id: string }[]> =
    new BehaviorSubject(null);

  legacyLocations$: BehaviorSubject<LegacyLocation[]> = new BehaviorSubject(
    null
  );

  selectedGroup$: BehaviorSubject<Group> = new BehaviorSubject(null);

  assets$: BehaviorSubject<
    {
      name: string;
      id: string;
      [name: string]: string | string[];
    }[]
  > = new BehaviorSubject(null);

  passholderTypeNameFromId$(passholderTypeId: string) {
    return this.passholderTypes$.pipe(
      filter(Boolean),
      map(
        (types: PassholderType[]) =>
          types.find((type) => type.id === passholderTypeId).name
      )
    );
  }

  getDivisions$(): Observable<{ name: string; id: string }[]> {
    return this.permissionsService.getDivisionMap().pipe(
      filter((divMap: IDivisionMap) => divMap !== null),
      map((divMap) => {
        let divisionIds: any[] = Object.keys(divMap);

        const divisions = divisionIds
          .map((d) => {
            return {
              name: divMap[d].name,
              id: d
            };
          })
          .filter((d) => divMap[d.id].type !== LEGACY_TYPES.LEGACY_LOCATION)
          .sort((a, b) => {
            const _a = a?.name?.toLowerCase();
            const _b = b?.name?.toLowerCase();
            if (_a < _b) {
              return -1;
            }
            if (_a > _b) {
              return 1;
            }
            return 0;
          });
        return divisions;
      })
    );
  }

  getLegacyLocations$(): Observable<LegacyLocation[]> {
    return this.permissionsService.getDivisionMap().pipe(
      filter((divMap) => divMap !== null),
      map((divMap) => {
        const legacyLocations = Object.values(divMap).reduce(
          (accumulator, entry) => {
            if (entry.type === 'LEGACY_LOCATION') {
              accumulator.push(entry);
            }
            return accumulator;
          },
          []
        );

        return legacyLocations as LegacyLocation[];
      })
    );
  }

  isSingleDivisionUser$(): Observable<boolean> {
    return this.divisions$.pipe(
      map((divisions) => {
        return divisions?.length <= 1;
      })
    );
  }

  shouldLoadData$(): Observable<boolean> {
    return combineLatest([
      this.isSingleDivisionUser$(),
      this.selectedDivisionId$.pipe(distinctUntilChanged())
    ]).pipe(
      map(([isSingleUser, divisionId]) => {
        if (isSingleUser) {
          return true;
        }

        if (divisionId) {
          return true;
        }

        return false;
      })
    );
  }
}
