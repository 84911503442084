import { Injectable } from '@angular/core';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import { HttpService } from '@zonar-ui/core';
import { AuthHeadersService } from '../auth-headers-service/auth-headers.service';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { DataDogService } from '../data-dog/data-dog.service';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetZoneNameService {
  constructor(
    private envService: GetEnvironmentService,
    private httpService: HttpService,
    private authService: AuthHeadersService,
    private datadogService: DataDogService
  ) {}

  coreEntityApiBaseUrl =
    this.envService.getEnvironmentProperty('coreEntityApiBase')['url'];

  zoneUrl = `${this.coreEntityApiBaseUrl}/zones`;
  private zoneCache = {};

  getZoneName(id: string): Observable<string> {
    if (!Boolean(id)) {
      return of(null);
    }
    if (this.zoneCache[id]) {
      return of(this.zoneCache[id]);
    }
    return this.authService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      switchMap((headers) => {
        const url = `${this.zoneUrl}/${id}`;
        return this.httpService.get(url, null, new HttpHeaders(headers)).pipe(
          map((res) => {
            const name = (res.body as any).name;
            this.zoneCache[id] = name;
            return name;
          })
        );
      }),
      catchError((err) => {
        const msg = `Error getting zone name: ${err}`;
        this.datadogService.addRumError(new Error(msg));
        return throwError(msg);
      })
    );
  }
}
