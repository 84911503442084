import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { PermissionsService } from '@zonar-ui/auth';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SelectedCompanyService } from '@app/services/selected-company/selected-company.service';
import { AppService } from '@src/app/app.service';
import { AuthHeaders } from './auth-headers.model';

@Injectable({
  providedIn: 'root'
})
export class AuthHeadersService {
  constructor(
    private authService: AuthService,
    private permsService: PermissionsService,
    private getEnvService: GetEnvironmentService,
    private selectedCompService: SelectedCompanyService,
    private appService: AppService
  ) {}

  private _authToken = null;
  jwtHelper = new JwtHelperService();
  appId = this.getEnvService.getEnvironmentProperty('auth')['applicationId'];

  getAuthToken() {
    if (this._authToken !== null) {
      const isExpired = this.jwtHelper.isTokenExpired(this._authToken);

      // if not expired, return the cached token
      if (!isExpired) {
        return of(this._authToken);
      }
    }
    return this.authService.getAccessTokenSilently().pipe(
      map((authToken) => {
        this._authToken = authToken;
        return authToken;
      })
    );
  }

  getAuthHeaders(): Observable<AuthHeaders> {
    return combineLatest([
      this.selectedCompService
        .getCompanyFromSideNav()
        .pipe(filter((company) => Boolean(company.value))),
      this.permsService
        .getUserProfiles()
        .pipe(filter((profiles) => Boolean(profiles?.length))),
      this.getAuthToken().pipe(filter((token) => Boolean(token))),
      // TODO: temp Zonar user hack
      this.permsService
        .getDivisionMap()
        .pipe(
          filter((divs) => Boolean(divs) && Boolean(Object.keys(divs)?.length))
        ),
      this.appService.selectedDivisionId$
    ]).pipe(
      map(([company, profiles, authToken, divisions, selectedDivId]) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        const firstDivision = Object.keys(divisions)[0];
        // TODO: temp Zonar user hack for zonar user to pass in division
        let profileId = null;
        const zpxAppProfiles = profiles?.filter(
          (p) => p.applicationId === this.appId
        );
        const zonarUserProfile = zpxAppProfiles?.find(
          (p) => p.companyId === null
        );

        if (zonarUserProfile) {
          profileId = zonarUserProfile.id;
        } else {
          const profile = zpxAppProfiles?.find(
            (p) =>
              p.companyId === company.value &&
              (p.divisions.includes(selectedDivId) || p.allDivisions)
          );
          if (profile) {
            let userRoles = [];
            profile.roles?.forEach((role) =>
              userRoles.push(role['permissions'])
            );
            this.selectedCompService.setUserPermissions(userRoles.flat());
          }
          profileId = profile?.id;
        }
        if (profileId) {
          const headers = {
            'zonar-owner-id': `User ${profileId}`,
            Authorization: `Bearer ${authToken}`
          };
          return headers;
        }
        return null;
      })
    );
  }
}
