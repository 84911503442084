<div class="zpx-filters-bar shadow-border" [ngClass]="mobileViewClasses">
  <h3 *ngIf="isMobile">Filter</h3>
  <zui-filters-bar
    [filterList]="filterList"
    [filterFormGroup]="filterFormGroup"
    (OnFilterChanged)="filterChanged($event)">
  </zui-filters-bar>
  <button
    class="mat-stroked-button mat-button-base accent-button mobile-view"
    *ngIf="isMobile"
    (click)="hideSelf()">
    Close
  </button>
</div>
