import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { EVENT_COLUMN_HEADERS } from '@models/zpx-api.model';
import {
  EventsForModalTable,
  ZpxEventForTable
} from '@app/views/events/models/events.model';
import { MatCardModule } from '@angular/material/card';

import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-event-details-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,

    MatTableModule
  ],
  templateUrl: './event-details-modal.component.html',
  styleUrls: ['./event-details-modal.component.scss']
})
export class EventDetailsModalComponent implements OnInit {
  formatDate: any;
  formatTime: any;
  constructor(public dialogRef: MatDialogRef<EventDetailsModalComponent>) {}
  dateTime: any;
  data: { event: ZpxEventForTable; action: string } = inject(MAT_DIALOG_DATA);

  dataSource: any[];
  displayedColumns = ['zone', 'group', 'address', 'dateTime'];
  ngOnInit(): void {
    this.dateTime = new Date(this.data.event.date_time);
    this.formatDate = this.dateTime.toLocaleDateString('en-US');
    this.formatTime = this.dateTime.toLocaleTimeString('en-US');

    this.dataSource = [
      {
        zone: this.data.event.zone,
        group: this.data.event.group,
        address: 'Address here',
        dateTime: `${this.formatDate} ${this.formatTime}`
      }
    ];
  }
}
