import { Injectable } from '@angular/core';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import { HttpService } from '@zonar-ui/core';
import { catchError } from 'rxjs/operators';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { DataDogService } from '../data-dog/data-dog.service';
import { Observable, throwError } from 'rxjs';
import { EntityApiParams } from '@src/app/models/entity-api.model';
import { AuthHeaders } from '../auth-headers-service/auth-headers.model';

@Injectable({
  providedIn: 'root'
})
export class EntityApiClientService {
  constructor(
    private envService: GetEnvironmentService,
    private httpService: HttpService,
    private datadogService: DataDogService
  ) {}

  coreEntityApiBaseUrl =
    this.envService.getEnvironmentProperty('coreEntityApiBase')['url'];

  // max number per ACE team
  perPage = 1000;

  PAGE_COUNT = 'x-page-count';
  TOTAL_COUNT = 'x-total-count';

  getResponseHeaderAsNumber(
    response: HttpResponse<any>,
    headerName: string
  ): number {
    return parseInt(response.headers.get(headerName));
  }
  get(
    resource: string,
    params: EntityApiParams,
    headers: AuthHeaders
  ): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {
        ...params,
        per_page: params.per_page || this.perPage
      }
    });

    const _headers = new HttpHeaders(headers);

    const url = `${this.coreEntityApiBaseUrl}/${resource}`;

    return this.httpService.get(url, httpParams, _headers).pipe(
      catchError((_) => {
        const msg = `Error fetching page ${params.page} of ${resource} from Entity API`;
        this.datadogService.addRumError(new Error(msg));
        return throwError(msg);
      })
    );
  }
}
