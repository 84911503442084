import { Injectable } from '@angular/core';
import { AppService } from '@src/app/app.service';
import {
  CustomTypeColumn,
  PASSHOLDER_COMMON_COLUMNS,
  PASSHOLDER_TYPES,
  ZpxApiFilterNames,
  ZpxApiPassholderGetReportBody
} from '@src/app/models/zpx-api.model';
import { makeDropdownOptions } from '@src/app/shared/utilities/utilities';

import {
  DropdownOptionsObject,
  FILTER_TYPE,
  SearchableDropdownModel
} from '@zonar-ui/filter';

import { BehaviorSubject, forkJoin, merge, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  COMMON_COLUMN_TYPES,
  CustomColumnSearchableDropdown,
  TablePassholdersFilterGroupValue
} from './models/table-passholders-filter-bar.model';

import _ from 'lodash-es';
import { ManagePassholdersService } from '@src/app/services/manage-passholders/manage-passholders.service';

@Injectable({
  providedIn: 'root'
})
export class TablePassholdersFilterBarService {
  constructor(
    private appService: AppService,
    private managePassholdersService: ManagePassholdersService
  ) {}

  displayedOptions = {
    divisionNameOptions$: new BehaviorSubject([] as DropdownOptionsObject[]),
    lastName$: new BehaviorSubject([] as DropdownOptionsObject[]),
    firstName$: new BehaviorSubject([] as DropdownOptionsObject[]),
    passNumber$: new BehaviorSubject([] as DropdownOptionsObject[]),
    uniqueId$: new BehaviorSubject([] as DropdownOptionsObject[]),
    groups$: new BehaviorSubject([] as DropdownOptionsObject[]),
    status$: new BehaviorSubject([
      { title: 'Active', value: 'Active' },
      { title: 'Inactive', value: 'Inactive' }
    ] as DropdownOptionsObject[])
  };

  // hack of making the "of" rxjs method a class function for easier spying in testing
  public of = of;

  // This is used to maintain the state of the user-selected filter options
  filterBody$: BehaviorSubject<ZpxApiPassholderGetReportBody> =
    new BehaviorSubject({ active: [true] });

  // this is currently just used in mobile implementation to reset the pagination page to handle infinite scrolling
  passholdersFilterChanged$: BehaviorSubject<boolean> = new BehaviorSubject(
    undefined
  );

  private commonDropdownProps = {
    isMultiple: true,
    blueCheckmarks: true,
    inputParams: []
  };

  public getDivisonModel(divisionId: string): SearchableDropdownModel {
    return {
      type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
      options: {
        label: 'Division Name',
        data: this.displayedOptions.divisionNameOptions$,
        fgControlName: ZpxApiFilterNames.DIVISION_ID,
        valueType: 'string',
        enableAllOptions: false,
        paramName: 'division_id',
        defaultValue: divisionId,
        isMultiple: false,
        blueCheckmarks: false,
        inputParams: []
      }
    };
  }

  public getCustomColumnFilters$(
    passholderType: PASSHOLDER_TYPES
  ): Observable<CustomColumnSearchableDropdown[]> {
    return this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divisionId: string) =>
        this.managePassholdersService
          .getCustomColumns(divisionId, passholderType)
          .pipe(
            filter((customColumns: CustomTypeColumn[]) =>
              Boolean(customColumns)
            ),
            map((customColumns) => {
              if (!customColumns.length) {
                return [];
              }
              const customColumnFilters = customColumns.map((cc) => {
                this.displayedOptions[cc.id] = new BehaviorSubject(
                  [] as DropdownOptionsObject[]
                );
                const custColFilter: CustomColumnSearchableDropdown = {
                  type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
                  customColumnId: cc.id,
                  options: {
                    label: cc.name,
                    data: this.displayedOptions[cc.id],
                    fgControlName: `custCol_${cc.id}`,
                    valueType: 'string',
                    enableAllOptions: false,
                    paramName: cc.id,
                    ...this.commonDropdownProps
                  }
                };
                return custColFilter;
              });

              return customColumnFilters;
            })
          )
      )
    );
  }

  public getCommonColumnsFilters(): SearchableDropdownModel[] {
    return [
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Division Name',
          data: this.displayedOptions.divisionNameOptions$,
          fgControlName: ZpxApiFilterNames.DIVISION_ID,
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'division_id',
          inputParams: [],
          isMultiple: false,
          blueCheckmarks: false
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Status',
          data: this.displayedOptions.status$,
          fgControlName: 'active',
          valueType: 'boolean',
          enableAllOptions: false,
          paramName: 'status',
          defaultValue: 'Active',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Last Name',
          data: this.displayedOptions.lastName$,
          fgControlName: 'last_name',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'lastName',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'First Name',
          data: this.displayedOptions.firstName$,
          fgControlName: 'first_name',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'firstName',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Card No.',
          data: this.displayedOptions.passNumber$,
          fgControlName: 'pass_number',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'passNumber',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Unique Id',
          data: this.displayedOptions.uniqueId$,
          fgControlName: 'exsid',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'uniqueId',
          ...this.commonDropdownProps
        }
      },
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label: 'Group',
          data: this.displayedOptions.groups$,
          fgControlName: 'group',
          valueType: 'string',
          enableAllOptions: false,
          paramName: 'groupName',
          ...this.commonDropdownProps
        }
      }
    ];
  }

  resetFilter() {
    // reset to the 'default view', which should be active passholders
    this.filterBody$.next({ active: [true] });
  }

  parseUserSelectedFilters(
    selectedFilterObj: TablePassholdersFilterGroupValue
  ) {
    const filterableKeys = [
      'first_name',
      'last_name',
      'exsid',
      'group',
      'pass_number'
    ];
    const formattedBody = { ..._.pick(selectedFilterObj, filterableKeys) };
    const specialMappingRequired = _.omit(selectedFilterObj, filterableKeys);
    // Special treatment for atypical filter keys
    _.forEach(specialMappingRequired, (val, key) => {
      if (key == 'active') {
        const vals = [];
        if (val.includes('Active')) {
          vals.push(true);
        }
        if (val.includes('Inactive')) {
          vals.push(false);
        }
        formattedBody['active'] = vals;
      }
      if (key.includes('custCol_')) {
        // Ensure that custom_columns key exists
        formattedBody.custom_columns = formattedBody.custom_columns || {};
        const custColId = key.split('custCol_')[1];
        formattedBody.custom_columns[custColId] = val;
      }
    });

    this.filterBody$.next(formattedBody);
  }

  private _convertToDropdownObjects(arr: string[]): DropdownOptionsObject[] {
    if (arr) {
      return arr
        .map((x) => ({ title: x, value: x }))
        .sort((a, b) => {
          const _a = a.title.toLowerCase();
          const _b = b.title.toLowerCase();
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        });
    }
  }

  public setCommonColumnFiltersOptions(passholderType: PASSHOLDER_TYPES) {
    const fNameObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) =>
        this.managePassholdersService
          .getPassholderCommonColumnValues(
            divId,
            passholderType,
            PASSHOLDER_COMMON_COLUMNS.FIRST_NAMES
          )
          .pipe(
            map((resp) => {
              const firstNameOptions = this._convertToDropdownObjects(
                resp.first_names
              );

              this.displayedOptions.firstName$.next(firstNameOptions);
              return 'first_names';
            })
          )
      )
    );

    const lNameObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) =>
        this.managePassholdersService
          .getPassholderCommonColumnValues(
            divId,
            passholderType,
            PASSHOLDER_COMMON_COLUMNS.LAST_NAMES
          )
          .pipe(
            map((resp) => {
              const lastNameOptions = this._convertToDropdownObjects(
                resp.last_names
              );

              this.displayedOptions.lastName$.next(lastNameOptions);
              return 'last_names';
            })
          )
      )
    );
    const uniqueIdObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) =>
        this.managePassholdersService
          .getPassholderCommonColumnValues(
            divId,
            passholderType,
            PASSHOLDER_COMMON_COLUMNS.EXSIDS
          )
          .pipe(
            map((resp) => {
              const exsidOptions = this._convertToDropdownObjects(resp.exsids);

              this.displayedOptions.uniqueId$.next(exsidOptions);
              return 'exsids';
            })
          )
      )
    );
    const passNumbersObs$ = this.appService.selectedDivisionId$.pipe(
      filter(Boolean),
      switchMap((divId: string) =>
        this.managePassholdersService
          .getPassholderCommonColumnValues(
            divId,
            passholderType,
            PASSHOLDER_COMMON_COLUMNS.NUMBERS
          )
          .pipe(
            map((resp) => {
              const numbersOptions = this._convertToDropdownObjects(
                resp.pass_numbers
              );

              this.displayedOptions.passNumber$.next(numbersOptions);
              return 'numbers';
            })
          )
      )
    );
    // TODO This probably only needs to be called once, since groups span across passholder types
    const groupsObs$ = this.appService.groupsByNameAsc$.pipe(
      filter((groups) => groups !== null),
      map((groups) => {
        const groupOptions = groups
          .filter((group) => group.active)
          .map((group) => ({
            title: group.name,
            value: group.id
          }));
        this.displayedOptions.groups$.next(groupOptions);
        return 'groups';
      })
    );

    const divisionObs$ = this.appService.divisions$.pipe(
      filter((divs) => divs !== null),
      map((divs) => {
        const divOptions = makeDropdownOptions(divs, 'name', 'id');

        this.displayedOptions.divisionNameOptions$.next(divOptions);
        return 'divisions';
      })
    );

    const colFiltersMap = {
      firstNames: {
        obs: fNameObs$,
        options: this.displayedOptions.firstName$
      },
      lastNames: {
        obs: lNameObs$,
        options: this.displayedOptions.lastName$
      },
      uniqueIds: {
        obs: uniqueIdObs$,
        options: this.displayedOptions.uniqueId$
      },
      numbers: {
        obs: passNumbersObs$,
        options: this.displayedOptions.passNumber$
      },
      groups: {
        obs: groupsObs$,
        options: this.displayedOptions.groups$
      },
      divisons: {
        obs: divisionObs$,
        options: this.displayedOptions.divisionNameOptions$
      }
    };
    const getCommonColOptionsObss$ = [];

    Object.keys(colFiltersMap).forEach((k: COMMON_COLUMN_TYPES) => {
      getCommonColOptionsObss$.push(colFiltersMap[k].obs);
    });

    // Run requests in parallel
    return merge(...getCommonColOptionsObss$);
  }

  getCustomColumnsFiltersOptions(
    customColumnFilters: CustomColumnSearchableDropdown[]
  ) {
    const customColOptionsObs$ = [];

    customColumnFilters.forEach((ccf) => {
      customColOptionsObs$.push(
        this.managePassholdersService
          .getCustomColumnValues(ccf.customColumnId)
          .pipe(
            map((resp) => {
              const customColumnValues = this._convertToDropdownObjects(
                resp.values
              );
              this.displayedOptions[ccf.customColumnId].next(
                customColumnValues
              );
            })
          )
      );
    });
    // Run requests in parallel
    return merge(...customColOptionsObs$);
  }
}
