import { PASSHOLDER_TYPES } from '@src/app/models/zpx-api.model';
import {
  DropdownOptionsObject,
  SearchableDropdownModel
} from '@zonar-ui/filter';

export enum EVENT_TYPES {
  CREATED = 'Created Events',
  EDITED = 'Edited Events',
  INACTIVE = 'Inactive Card',
  INCOMPLETE = 'Incomplete Data',
  NORMAL = 'Normal Events',
  UNASSIGNED = 'Unassigned Card'
}

export enum ZPX_EVENT_PARAM_NAMES {
  START_TIME = 'from_date',
  END_TIME = 'to_date',
  PASSHOLDER_TYPE_IDS = 'passholder_type_ids',
  GROUPS = 'group_ids',
  INCLUDE_IGNORED = 'include_ignored',
  UNIQUE_IDS = 'exsids',
  EVENT_FLAGS = 'event_flags',
  FIRST_NAMES = 'first_names',
  LAST_NAMES = 'last_names',
  DIVISION_ID = 'division_id',
  // waiting for official param name from API
  ASSET_NO = 'asset_names'
}

export interface ZpxApiEventGetReportBodyParams {
  from_date?: string;
  to_date?: string;
  event_flags?: string[];
  include_ignored?: boolean;
  division_id?: string;
  group_ids?: string[];
  passholder_type_ids?: string[];
  first_names?: string[];
  last_names?: string[];
  exsids?: string[];
  offset?: number;
  limit?: number;
}
export interface RangeParams {
  [ZPX_EVENT_PARAM_NAMES.START_TIME]?: string;
  [ZPX_EVENT_PARAM_NAMES.END_TIME]?: string;
}

export interface ZpxEvent {
  id: string;
  company_id: string;
  division_id: string;
  ph_first_name: string;
  ph_last_name: string;
  p_number: string;
  ph_exsid: string;
  ph_group_name: string;
  event_ts: string;
  event_flags: string[];
  ph_type_id: string;
  asset_name: string;
  zone: string;
  class?: string;
}
export interface ZpxApiEventGetReportHttpResponseBody {
  total_count: number;
  count: number;
  offset: number;
  data: ZpxEvent[];
}

export interface ZpxEventForTable {
  last_name: string;
  first_name: string;
  card_number: string;
  ch_type?: string;
  zone: string;
  asset_no: string;
  group: string;
  unique_id: string;
  date: string;
  event_type: string;
  class?: string;
  date_time: string;
}

export interface CachedPassholderTypesFiltersModel {
  student?: PassholderTypeFiltersCache;
  driver?: PassholderTypeFiltersCache;
  aide?: PassholderTypeFiltersCache;
}

export interface CommonColumnsFilters {
  dropDownOptions: { [key in COMMON_COLUMN_TYPES]?: DropdownOptionsObject[] };
}

export enum COMMON_COLUMN_TYPES {
  FIRST_NAMES = 'firstNames',
  LAST_NAMES = 'lastNames',
  DRIVER = 'driver',
  UNIQUE_IDS = 'uniqueIds',
  NUMBERS = 'numbers',
  GROUPS = 'groups',
  DIVISIONS = 'divisions',
  CARDHOLDER_TYPE = 'cardholderType',
  SHOW_IGNORED_EVENTS = 'showIgnoredEvents'
}
export interface EventsForModalTable {
  zone: string;
  group: string;
  address: string;
  date: string;
}
export class PassholderTypeFiltersCache {
  passholderType: PASSHOLDER_TYPES;
  commonColumns: CommonColumnsFilters;
  constructor(passholderType: PASSHOLDER_TYPES) {
    this.passholderType = passholderType;
    this.commonColumns = {
      dropDownOptions: {}
    };
  }
  getCommonColumnsOptions() {
    return this.commonColumns.dropDownOptions;
  }
  resetCache() {
    this.commonColumns.dropDownOptions = {};
  }
}
