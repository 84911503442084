<div id="events-table" class="shadow-border">
  <app-zpx-table
    (rowClick)="onClick($event)"
    [columns]="columns$"
    [enableMobileTable]="enableMobileTable"
    [showTableHeader]="showTableHeader"
    title="Z Pass Event Report"
    [dataSource]="dataSource">
    <div class="button-container">
      <button
        class="mat-stroked-button mat-button-base white-button mat-button-disabled">
        Ignore Events
      </button>

      <button class="mat-stroked-button mat-button-base white-button">
        Export
      </button>
      <button class="mat-stroked-button mat-button-base white-button">
        Add Event +
      </button>

      <mat-icon style="margin-right: 3px" class="mat-accent"
        >help_outline</mat-icon
      >
    </div>
  </app-zpx-table>
</div>
