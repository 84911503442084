/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PASSHOLDER_TYPES } from '@models/zpx-api.model';
import { FormGroup } from '@angular/forms';

import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  switchMapTo,
  takeUntil,
  tap
} from 'rxjs/operators';

import { AppService } from '@app/app.service';
import { Subject, combineLatest } from 'rxjs';
import {
  SearchableDropdownModel,
  ZuiFiltersBarComponent
} from '@zonar-ui/filter';
import _ from 'lodash';
import { EventsFilterBarService } from '../services/events-filter-bar.service';
import { ZuiDatePickerModule } from '@zonar-ui/date-picker';
import {
  RangeParams,
  ZpxApiEventGetReportBodyParams,
  ZPX_EVENT_PARAM_NAMES
} from '../models/events.model';

@Component({
  selector: 'app-events-filter-bar',
  standalone: true,
  imports: [CommonModule, ZuiFiltersBarComponent, ZuiDatePickerModule],
  templateUrl: './events-filter-bar.component.html',
  styleUrls: ['./events-filter-bar.component.scss']
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class EventsFilterBarComponent implements OnInit, OnChanges {
  constructor(
    public filterBarService: EventsFilterBarService,
    public appService: AppService
  ) {}
  @Output() mobileDisplayed = new EventEmitter();
  @Input() isMobile = false;

  @Input() toggleDisplay: boolean;
  private unsubscribe$ = new Subject<void>();
  passholderType = PASSHOLDER_TYPES.STUDENT;
  filterList: SearchableDropdownModel[];
  filterFormGroup = new FormGroup({});
  shouldSendDivisionId: boolean = null;
  changedEvent: any = null;
  selectedDivisionId: string = null;
  selectedRange: RangeParams = null;

  mobileViewClasses = {
    'mobile-view': this.isMobile ? true : false,
    hidden: false
  };
  removeDivisionFilter() {
    const first: SearchableDropdownModel = this.filterList[0];
    if (first.options.fgControlName === ZPX_EVENT_PARAM_NAMES.DIVISION_ID) {
      this.filterList.shift();
    }
  }

  setDivisionDefaultSelection(divisionId: string) {
    this.filterList[0] = this.filterBarService.getDivisonModel(divisionId);
  }

  filterChanged(e) {
    if (this.changedEvent === null) this.changedEvent = e;

    // for some reason, the zui filters bar doesn't enable the date picker to be part of the emitting form group, so we have to capture it separately and add it later to filter params at bottom of this method, see "selectedFilterObj"
    if (
      e[ZPX_EVENT_PARAM_NAMES.START_TIME] &&
      e[ZPX_EVENT_PARAM_NAMES.END_TIME]
    ) {
      this.selectedRange = {
        [ZPX_EVENT_PARAM_NAMES.START_TIME]: e[ZPX_EVENT_PARAM_NAMES.START_TIME],
        [ZPX_EVENT_PARAM_NAMES.END_TIME]: e[ZPX_EVENT_PARAM_NAMES.END_TIME]
      };
    }
    if (!_.isEqual(this.changedEvent, e)) {
      this.changedEvent = e;

      this.shouldSendDivisionId =
        Boolean(e?.division_id?.selected) && Object.keys(e).length === 1;

      if (this.shouldSendDivisionId) {
        const incomingDivisionId = e.division_id.selected;

        // saving of selectedDivisionId prevents emissions of the same division id and prevents redundant api calls
        if (this.selectedDivisionId !== incomingDivisionId) {
          this.selectedDivisionId = incomingDivisionId;
          this.appService.selectedDivisionId$.next(this.selectedDivisionId);
        }
      } else {
        // We're not switching division IDs, so the filter values need to be acted upon.
        // We need to do this little bit because divisionId is a string, and we also only
        // care about the state of the filters that have been selected
        const filterFormState: ZpxApiEventGetReportBodyParams =
          this.filterFormGroup.value;
        const selectedFilterObj: ZpxApiEventGetReportBodyParams = {};
        Object.keys(filterFormState)
          .filter(
            (ff) =>
              Array.isArray(filterFormState[ff]) &&
              filterFormState[ff].length > 0
          )
          .forEach(
            (selectedFilter) =>
              (selectedFilterObj[selectedFilter] =
                filterFormState[selectedFilter])
          );

        if (this.selectedRange) {
          selectedFilterObj.from_date =
            this.selectedRange[ZPX_EVENT_PARAM_NAMES.START_TIME];

          selectedFilterObj.to_date =
            this.selectedRange[ZPX_EVENT_PARAM_NAMES.END_TIME];
        }

        // since include_ignored is not an array, it gets filtered out from above processes, so manually add it here
        if (
          filterFormState.hasOwnProperty(ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED)
        ) {
          // This is needed because boolean values are unable to be set as SEARCHABLE_DROPDOWN
          // option defaultValues. In order to have 'False' as our default value, it needs to be a string
          // so we need to convert it to the necessary bool to be sent to the API here
          const ignored =
            // @ts-ignore::next-line
            filterFormState[ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED] === 'true'
              ? true
              : false;
          selectedFilterObj[ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED] = ignored;
        }
        this.filterBarService.eventFilterChanged$.next(true);
        this.filterBarService.parseUserSelectedFilters(selectedFilterObj);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isMobile'] !== undefined) {
      // ensure that filter bar is hidden the first time mobile view is entered (either on init, or when dynamically resizing the page)
      this.mobileViewClasses.hidden = changes['isMobile'].currentValue === true;
      this.mobileViewClasses['mobile-view'] = changes['isMobile'].currentValue;
      return;
    }
    if (changes['toggleDisplay'] !== undefined) {
      this.mobileViewClasses.hidden = !this.mobileViewClasses.hidden;

      this.mobileDisplayed.emit(!this.mobileViewClasses.hidden);
    }
  }

  ngOnInit() {
    this.filterList = this.filterBarService.getCommonColumnsFilters();

    this.appService
      .shouldLoadData$()
      .pipe(
        filter(Boolean),
        switchMap(() =>
          combineLatest([
            this.appService.selectedDivisionId$.pipe(filter(Boolean)),
            this.appService.isSingleDivisionUser$().pipe(distinctUntilChanged())
          ]).pipe(
            map(([divId, singleDivUser]) => {
              this.selectedDivisionId = divId as string;
              singleDivUser
                ? this.removeDivisionFilter()
                : this.setDivisionDefaultSelection(this.selectedDivisionId);
            })
          )
        ),
        switchMapTo(this.filterBarService.setCommonColumnFiltersOptions()),
        tap(() => {
          // This is kind of hacky, and it seems like it's due to a race condition between when the
          // observable data values of the SEARCHABLE_DROPDOWN filter models are being set compared to when those
          // models get added to the filterFormGroup with a 'defaultValue' option property
          const divIdForm =
            this.filterFormGroup.controls[ZPX_EVENT_PARAM_NAMES.DIVISION_ID];
          if (divIdForm) {
            if (divIdForm.value !== this.selectedDivisionId) {
              divIdForm.setValue(this.selectedDivisionId);
            }
          }
        }),

        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
