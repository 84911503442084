<div
  id="events-filter-bar"
  [ngClass]="mobileViewClasses"
  class="zpx-filters-bar shadow-border"
  [ngClass]="mobileViewClasses">
  <h3 *ngIf="isMobile">Filter</h3>
  <zui-filters-bar
    [filterList]="filterList"
    [filterFormGroup]="filterFormGroup"
    (OnFilterChanged)="filterChanged($event)">
  </zui-filters-bar>
</div>
